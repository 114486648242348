import type { RootState } from "./store"
import type { PdfNamingScheme, PDFNotificationOption } from "../common/types/api.personalDataField.type"
import type { Link } from "../common/types/api.link.type"
import { Card } from "../common/types/api.card.types"
import Cardholder, { CardEditDef } from "../common/types/api.cardholder.type"
import { pdfSort } from "../common/utils/pdfSort"
import PDFType from "../common/types/PDFEnum.type"
import { createSelector } from "@reduxjs/toolkit"
import { CredentialEditorConfig } from "../components/CredentialEditor/CredentialEditor.types"

export type NotificationTypes = string | number | Link

export type NotificationPrefs = {
  email?: NotificationTypes
  mobile?: NotificationTypes
}

export const selectAccessGroups = createSelector([(state: RootState) => state.person?.editable?.accessGroups], (accessGroups) => {
  return !accessGroups
    ? []
    : [...accessGroups].sort((a, b) => {
        return a.accessGroup.name.localeCompare(b.accessGroup.name, undefined, { numeric: true, sensitivity: "base" })
      })
})

export const selectHasChanges = (state: RootState) => !!state.person?.hasChanges
export const selectIsValid = (state: RootState) => !!state.person?.isValid
export const selectActiveTab = (state: RootState) => state.person?.activeTab
export const selectCardholder = (state: RootState) => state.person?.original
export const selectCardholderUrl = (state: RootState) => state.person?.original?.href!
export const selectEditableCardholder = (state: RootState) => state.person?.editable
export const selectCards = (state: RootState) => state.person?.editable?.cards
export const selectActivityData = (state: RootState) => state.person.activityResults
export const selectActivityUpdatesUrl = (state: RootState) => state.person.activityUpdates
export const selectProfilePictureUrl = (state: RootState) => {
  if (!state.person.original) return undefined
  const thumbnailPdfs = state.session.session?.features?.cardholders?.thumbnails?.pdfIds
  if (!thumbnailPdfs || thumbnailPdfs.length === 0) return undefined
  const PDFDefinitions = state.person?.original?.personalDataDefinitions
  let url: string | undefined
  loop1: for (const pdfId of thumbnailPdfs) {
    for (const pdfDef of PDFDefinitions ?? []) {
      const key: any = Object.keys(pdfDef)[0]
      if (pdfDef[key].definition.id === pdfId && state.person.original?.hasOwnProperty(key)) {
        url = (state.person.original[key] as any).href
        break loop1
      }
    }
  }
  return url?.concat("?", state.session.timestamp) //using timestamp to force browser refresh the image
}
export const selectAccessGroupAssign = (state: RootState) => state.person.editable?.editable?.accessGroups?.assign
export const selectCanAddCards = (state: RootState) => state.person.editable?.editable?.cards?.assign

export const selectEmailAndMobile = (state: RootState) => {
  if (!state.person?.original?.options) return {}
  // get all PDFs which have notifications set

  type NotificationPDF = {
    name: string
    option: PDFNotificationOption
    enabled: boolean
    val?: NotificationTypes
  }

  const emails: NotificationPDF[] = []
  const mobiles: NotificationPDF[] = []

  Object.entries(state.person.original.options).forEach(([pdfName, pdfOpt]) => {
    const pdfDef = state.person.original?.personalDataDefinitions?.find((p) => p[pdfName as PdfNamingScheme])
    const pdf = pdfDef?.[pdfName as PdfNamingScheme]
    if (pdf) {
      if (pdf.definition.type === PDFType.Email) {
        emails.push({ name: pdfName, option: pdfOpt, enabled: pdf.notifications, val: state.person.original?.[pdfName as PdfNamingScheme] })
      }
      if (pdf.definition.type === PDFType.Mobile) {
        mobiles.push({ name: pdfName, option: pdfOpt, enabled: pdf.notifications, val: state.person.original?.[pdfName as PdfNamingScheme] })
      }
    }
  })

  const getPriorityPDFVal = (arr: NotificationPDF[], pdfType: PDFType, ch: Cardholder) => {
    if (arr.length > 0) {
      return arr
        .filter((item) => item.option.type === pdfType && item.val)
        .sort((a, b) => {
          // if both have notifications or neither have notifications use existing sort
          if ((a.enabled && b.enabled) || (!a.enabled && !b.enabled)) return pdfSort(a.option, b.option)

          // if a has notifications but not b
          if (a.enabled && !b.enabled) return -1
          if (!a.enabled && b.enabled) return 1
          return 0
        })?.[0]?.val
    }
  }

  return {
    email: getPriorityPDFVal(emails, PDFType.Email, state.person.original),
    mobile: getPriorityPDFVal(mobiles, PDFType.Mobile, state.person.original)
  }
}

export const selectHasMobileCred = (state: RootState) => !!state.person.editable?.cards?.some((c) => c.credentialClass === "mobile")
export const selectCardTypes = (state: RootState) => state.person.cardTypes
const selectCardPermissions = (state: RootState) => state.person.editable?.editable?.cards?.edit
const selectCardOptions = (state: RootState) => state.person.editable?.options?.cards
const selectHref = (state: RootState, href: string | undefined) => href
export const selectCardInfo = createSelector([selectCardPermissions, selectCardOptions, selectCardTypes, selectHref], (permissions, options, cardTypes, href) => {
  return {
    permissions: permissions?.[href as keyof CardEditDef],
    options: options?.[href as keyof CardEditDef],
    cardTypes
  }
})
export const selectCanDeauthorise = (state: RootState) => !!state.person.original?.editable?.authorised
export const selectEditableIsAuthorised = (state: RootState) => !!state.person.editable?.authorised
export const selectDivisionsAsArray = (state: RootState) => state.person.divisions?.map((div) => [div.name, div.href] as [string, string])
export const selectOriginalCards = (state: RootState) => state.person.original?.cards as Card[]

export const selectIssuedDigitalCardTypes = (state: RootState) => state.person?.original?.cards?.filter((c) => c.credentialClass === "digitalId").map((c) => c.type?.href ?? "") ?? []

export const selectCredentialEditorConfig = createSelector(
  [selectCardTypes, selectEmailAndMobile, selectHasMobileCred, selectCardholderUrl, selectIssuedDigitalCardTypes],
  (cardTypes, notifications, hasMobileCred, cardholderHref, issuedDigitalIDCardTypes) => {
    return {
      cardTypes,
      notifications,
      hasMobileCred,
      cardholderHref,
      issuedDigitalIDCardTypes
    } as CredentialEditorConfig
  }
)
