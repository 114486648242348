import React, { useState } from "react"
export type PopupStackComponentProps = { onClose: () => void }
export type PopupStackProps = {
  components: React.ComponentType<PopupStackComponentProps>[]
}
/*** This component is used to render a stack of popups one after another is closed */
const PopupStack = ({ components }: PopupStackProps) => {
  const [index, setIndex] = useState(0)
  return <>{components.map((Child, i) => (i === index ? <Child key={i} onClose={() => setIndex(i + 1)} /> : null))}</>
}
export default PopupStack
