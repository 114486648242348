import { useCallback, useState } from "react"

const getLocalStorageItem = <T>(key: string, defaultValue: T) => {
  let localStorageValue: string | null = null
  try {
    localStorageValue = localStorage.getItem(key)
    if (localStorageValue) {
      const parsed = JSON.parse(localStorageValue) as T
      return parsed as T
    }
  } catch {}

  return defaultValue
}

const useLocalStorage = <TValue>(key: string, defaultValue: TValue) => {
  const [value, setValue] = useState<TValue>(() => getLocalStorageItem(key, defaultValue))

  const setLocalStorageValue = useCallback(
    (newValue: TValue) => {
      let strValue: string
      if (typeof newValue === "string") {
        strValue = newValue
      } else if (typeof newValue === "object") {
        strValue = JSON.stringify(newValue)
      } else {
        strValue = String(newValue)
      }

      localStorage.setItem(key, strValue)
      setValue(newValue)
    },
    [setValue]
  )

  return {
    value,
    setLocalStorageValue
  }
}

export default useLocalStorage
