import type { Results } from "../common/types/api.search.type"
import type { CardTypeQuery } from "../common/types/api.cardType.types"
import type { LinkWithIdAndName, LinkWithName } from "../common/types/api.link.type"
import type { RootState } from "./store"
import type Cardholder from "../common/types/api.cardholder.type"
import type { ActivityCollection } from "../components/Cardholder/Activity/ActivityTab"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { get } from "../common/utils/apiCall"
import { appendQueryString } from "../common/utils/appendQueryString"
import { ErrorList } from "../common/utils/errors"
import invariant from "../common/utils/invariant"
import { setBanner } from "./session.slice"
import { selectCanAddCardholderUrl } from "./session.selectors"

const NETWORK_ERROR_MESSAGE = "Network error"

export const fetchCardholder = createAsyncThunk("person/fetchCardholder", async (url: string, thunkAPI) => {
  try {
    invariant(url, ErrorList.NoURL)
    const cardholder = await get<Cardholder>(url)
    const division = await get<LinkWithIdAndName>(cardholder.division!.href)
    cardholder.division = division

    invariant(cardholder)
    // Ensures default fields are accessible
    const cardholderDefault = { ...cardholder }
    const defaults: Partial<Cardholder> = {
      firstName: cardholder.firstName || "",
      lastName: cardholder.lastName || "",
      shortName: cardholder.shortName || "",
      description: cardholder.description || ""
    }
    for (let key in defaults) {
      ;(cardholderDefault as any)[key] = (defaults as any)[key]
    }
    return cardholderDefault
  } catch (err: unknown) {
    return thunkAPI.rejectWithValue(err)
  }
})

export const fetchCardTypes = createAsyncThunk("person/fetchCardTypes", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState
    const url = state.session.session?.features?.cardholders?.cardTypes?.assign.href!
    invariant(url, ErrorList.NoURL)
    const cards = await get<CardTypeQuery>(url)
    invariant(cards)
    invariant(cards.results)
    return cards.results
  } catch (err: unknown) {
    return thunkAPI.rejectWithValue(err)
  }
})

/**
 * This method will take a collection of fields and request them from the server
 * The reducer that parses this response will then merge the new fields into the authorative cardholder copy and the editable draft
 * @param fields - string or number or undefined
 * @returns { fields: Array<keyof Cardholder>, cardholder: Partial<Cardholder> }
 */
export const fetchAndMergeFields = createAsyncThunk(
  "person/fetchAndMergeFields",
  async (fields: Array<keyof Cardholder>, thunkAPI) => {
    try {
      invariant(fields)
      const state = thunkAPI.getState() as RootState
      invariant(state.person.original?.href)
      const url = appendQueryString(state.person.original.href, {
        fields: fields.join(",")
      })
      const ch = await get<Partial<Cardholder>>(url)
      // the api won't always return values if they are blank
      // so we will create this stub then merge it with the response to make sure we have what we requested
      const defaults = Object.fromEntries(fields.map((f) => [f, undefined]))
      return {
        fields: fields,
        cardholder: { ...defaults, ...ch } // merge the stub with the API response
      }
    } catch (err: unknown) {
      if (err instanceof Error) thunkAPI.dispatch(setBanner({ color: "destructive", message: err.message }))
    }
  }
)

export const fetchActivity = createAsyncThunk("person/fetchActivity", async (url: string, thunkAPI) => {
  try {
    invariant(url, ErrorList.NoURL)
    const ac = await get<ActivityCollection>(url, { signal: thunkAPI.signal } as AbortController)
    invariant(ac)
    return ac
  } catch (err: unknown) {
    if (err instanceof Error && err.name !== "AbortError") {
      // User has cancelled this fetch, don't set banner
      thunkAPI.dispatch(setBanner({ color: "destructive", message: err.message ?? NETWORK_ERROR_MESSAGE }))
    }
    return thunkAPI.rejectWithValue(err)
  }
})

export const fetchDivisions = createAsyncThunk("person/fetchDivisions", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState
    const url = selectCanAddCardholderUrl(state)?.href
    invariant(url, ErrorList.NoURL)
    const divisions = await get<Results<LinkWithName>>(url)
    invariant(divisions)
    return divisions.results
  } catch (err: unknown) {
    return thunkAPI.rejectWithValue(err)
  }
})
