export const DEFAULT_THEME = "dark"

/**
 * Represents the possible theme options for the application.
 * - 'light': Light theme
 * - 'dark': Dark theme
 * - 'auto': Automatically selects theme based on system preference
 */
const VALID_THEMES = ["dark", "light", "auto"] as const
export type Theme = (typeof VALID_THEMES)[number]

const THEME_LOCALSTORAGE_KEY = "theme"

/**
 * Sets the theme for the application by updating the 'data-theme' attribute on the document's root element.
 *
 * @param theme The theme to set. Can be 'dark', 'light', or 'auto'. Defaults to DEFAULT_THEME if not specified.
 * @returns The actual theme applied ('dark' or 'light')
 *
 * @example
 * setTheme('auto');
 *
 * @note When 'auto' is used, the function checks the user's system preference using the 'prefers-color-scheme' media query.
 *       The theme will be set to 'dark' if the system preference is dark, and 'light' otherwise.
 */
export function setTheme(theme: Theme = DEFAULT_THEME): Theme {
  if (!VALID_THEMES.includes(theme)) {
    console.warn(`Invalid theme "${theme}". Falling back to "${DEFAULT_THEME}".`)
    theme = DEFAULT_THEME
  }

  const appliedTheme: Theme = theme === "auto" ? getSystemPreferredTheme() : theme
  document.documentElement.setAttribute("data-theme", appliedTheme)

  localStorage.setItem(THEME_LOCALSTORAGE_KEY, theme)

  return appliedTheme
}

const isTheme = (x: unknown): x is Theme => typeof x === "string" && VALID_THEMES.includes(x as Theme)

/**
 * Retrieves the stored theme preference from localStorage and validates it.
 * @returns The stored theme ('light', 'dark', or 'auto'), or undefined if not set or invalid.
 */
export function getThemeInLocalStorage(): Theme | undefined {
  const value = localStorage.getItem(THEME_LOCALSTORAGE_KEY)
  return isTheme(value) ? value : undefined
}

const getSystemPreferredThemeMediaQuery = () => window.matchMedia("(prefers-color-scheme: dark)")

/**
 * Determines the system's preferred theme based on the user's color scheme preference.
 * Uses the `window.matchMedia` method to check if the user prefers a dark theme.
 *
 * @returns {Theme} - Returns "dark" if the user prefers a dark theme, otherwise "light".
 */
export function getSystemPreferredTheme(): Theme {
  return getSystemPreferredThemeMediaQuery().matches ? "dark" : "light"
}

/**
 * Sets up a listener for system theme changes and updates the theme if set to 'auto'.
 */
export function setupThemeListener(): () => void {
  const handleChange = () => {
    if (getThemeInLocalStorage() === "auto") setTheme("auto")
  }

  const mediaQuery = getSystemPreferredThemeMediaQuery()
  mediaQuery.addEventListener("change", handleChange)

  return () => mediaQuery.removeEventListener("change", handleChange)
}