export enum FTItemType {
  Cardholder = 1,
  Elmo = 4
}

export enum VerbId {
  New = 0,
  Delete = 991,
  View = 10000,
  Modify = 10001,
  Assign = 10002
}
