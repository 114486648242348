enum PDFType {
  Image = "image",
  Text = "string",
  TextList = "strEnum",
  Numeric = "numeric",
  Date = "date",
  Address = "address",
  Phone = "phone",
  Mobile = "mobile",
  Email = "email",
  String = "string"
}

export default PDFType
