/**
 * Deeply compare equality of two objects.
 * Empty strings and undefined are treated as equal
 * @returns true if equal, else false
 */
const deepEquals = <T extends object>(a?: T | null, b?: T | null) => {
  const replacer = (key: string, value: any) => ((value === "") || (value?.length === 0) ? undefined : value)
  const aJson = JSON.stringify(a, replacer)
  const bJson = JSON.stringify(b, replacer)
  return aJson === bJson
}

export default deepEquals
