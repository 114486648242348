import { useRef, useCallback } from "react"

/**
 * A custom React hook that provide a controlled way to manage execution frequency in the Appication.
 * This hook is useful when you want avoid multiple execution of common code/function from different entry points within a given time limit after first execution.
 *
 * @param delay The number of milliseconds to wait before executing the event handler again.
 * @returns A memoized function that will only invoke the event handler immediately first one and ignore next executions within the specified delay since the last call.
 *
 * @example
 * const handleEvent = useHandleDuplicateEvents(300)
 *
 * const eventHandler = () => {
 *  //rest of the code
 *  handleEvent(() => {//common code that needs to be restricted})
 * }
 *
 * @note This hook will throttel the event handler execution based on the delay provided.
 *
 */

const useHandleDuplicateEvents = (delay: number = 300) => {
  const lastEventTimeRef = useRef<number | null>(null)

  const handleEvent = useCallback(
    (eventHandler: () => void) => {
      const now = Date.now()
      if (lastEventTimeRef.current === null || now - lastEventTimeRef.current > delay) {
        eventHandler()
        lastEventTimeRef.current = now
      }
    },
    [delay]
  )

  return handleEvent
}

export default useHandleDuplicateEvents
