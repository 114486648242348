import { createSelector } from "@reduxjs/toolkit"
import type { Link } from "../common/types/api.link.type"
import { Features8_90, SessionOperatorInfo } from "../common/types/api.session.type"
import Version, { KnownCommandCentreVersion } from "../common/utils/version"
import type { RootState } from "./store"

export const selectOperatorUrl = (state: RootState) => state.session.session?.operator?.href

export const selectCustomerEndpoint = (state: RootState) => state.session.session?.features?.cardholders?.cardholders?.href
export const selectFeatures = (state: RootState) => state.session?.session?.features
export const selectLogoutHref = (state: RootState) => state.session?.session?.logoff?.href
export const selectCardholders = (state: RootState) => state.session?.session?.features?.cardholders?.cardholders?.href
export const selectThumbnailUrl = (state: RootState) => state.session?.session?.features?.cardholders?.thumbnails?.href
export const selectProfileImageIds = (state: RootState) => state.session.session?.features?.cardholders?.thumbnails?.pdfIds
export const selectEventsEndpoint = (state: RootState) => state.session.session?.features?.events?.events?.href
export const selectExpiry = (state: RootState) => state.session.expiry ?? 0
export const selectDrawerClosed = (state: RootState) => state.session?.drawerClosed ?? true
export const selectCurrentSessionOperatorInfo = (state: RootState): SessionOperatorInfo | undefined => state.session.session?.operator
export const selectThumbnailPDFIds = (state: RootState) => state.session.session?.features?.cardholders?.thumbnails?.pdfIds
export const selectPlatform = (state: RootState) => state.session?.platform
export const selectBanner = (state: RootState) => state.session?.banner
export const selectTimestamp = (state: RootState) => state.session?.timestamp
export const selectResponsiveLayout = (state: RootState) => state.session?.layout
export const selectCommandCentreVersion = (state: RootState) => state.session.session?.version
export const selectAccessGroupAssignLink = (state: RootState) => state.session.session?.features?.cardholders?.accessGroups?.assign
export const selectCredentialsAssign = (state: RootState) => state.session.session?.features?.cardholders?.cardTypes?.assign
export const selectAssignableAccessGroupsDivision = (state: RootState) => state.session.session?.features?.cardholders?.accessGroups?.assign?.divisions
export const selectSubscriptions = (state: RootState) => {
  return state.session.session?.subscriptions
}
// prettier-ignore
export const selectDivisionsUrl = (state: RootState) => (state.session.session?.features as Features8_90).divisions?.divisions

export const selectCardholderViewableDivisions = (state: RootState) => {
  return state.session.session?.features?.cardholders?.divisions?.href
}

export const selectCardholderAssignableDivisions = (state: RootState) => {
  return state.session.session?.features?.cardholders?.divisions?.assign
}

// prettier-ignore
export const selectCanAddCardholderUrl = createSelector(
  [selectCommandCentreVersion, selectCardholderAssignableDivisions, selectDivisionsUrl],
  (version, assignDivisionsUrl, divisionsUrl) => {
    return Version.greaterThanOrEqual(version, KnownCommandCentreVersion.GetDivisionsVerbAndFTItem) ? { href: `${(divisionsUrl as Link)?.href}?type=cardholder&verb=new` } : assignDivisionsUrl
  }
)

export const selectSiteSettingsUrl = (state: RootState) => state.session.session?.features?.site?.settings?.href
export const selectSiteSettingsUpdateUrl = (state: RootState) => state.session.session?.features?.site?.settings?.update?.href
export const selectOperatorSettingsUrl = (state: RootState) => state.session.session?.operator?.settings?.href
export const selectOperatorSettingsUpdateUrl = (state: RootState) => state.session.session?.operator?.settings?.update?.href

export const selectStatusAccessZoneUrl = (state: RootState) => state.session.session?.features?.status?.accessZones?.accessZones?.href