import { store } from "./store"
import { del } from "../common/utils/apiCall"

const invalidateSession = async () => {
  // terminate the session on the server
  const logout = store.getState().session.session?.logoff?.href
  try {
    if (logout) await del<void>(logout)
  } catch (err) {
    console.info(err)
  }

  store.dispatch({ type: "END_SESSION" })

  // when logout reset the path to the home page
  window.location.pathname = "/"

  //terminate polls
  window.sessionPoll?.stop()
}

export default invalidateSession
