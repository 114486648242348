import styles from "./mfaHelp.module.scss"
import { Button, Card, Title } from "@ggl/components"
import React, { useState } from "react"
import { useAppSelector, selectResponsiveLayout } from "../../../store"
import { captureTelemetry } from "../../../common/utils/postHog"

interface mfaProps {
  setMfaHelpDisplay: any
}

const MfaHelp = ({ setMfaHelpDisplay }: mfaProps) => {
  const layout = useAppSelector(selectResponsiveLayout)
  const [smallCardIndex, setSmallcardIndex] = useState(1)
  return (
    <>
      {layout === "desktop" && (
        <Card className={styles.card} id="mfa_help_card">
          <div className={styles.cardCol}>
            <div className={styles.smallCard}>
              <Title text="Step 01" />
              <img src="/img/mobile_connect_icon.png" className={styles.mcIcon} id="mfa_help_img_1" aria-hidden={true} alt=""></img>
              <div className={styles.helpText}>
                <p>Download and open the mobile connect App on your mobile device.</p>
                <b>Note: Make sure you have a valid Credential.</b>
              </div>
            </div>
            <div className={styles.smallCard}>
              <Title text="Step 02" />
              <img src="/img/mobile_connect_code.png" className={styles.mcCode} id="mfa_help_img_2" aria-hidden={true} alt=""></img>
              <div className={styles.helpText}>
                <p>Under the &quot;cards&quot; tab you will find a 6-digit code. Enter in this code when prompted by Command Centre Web.</p>
              </div>
            </div>
            <div className={styles.smallCard}>
              <Title text="Step 03" />
              <img src="/img/mobile_connect_clock.png" className={styles.mcClock} id="mfa_help_img_3" aria-hidden={true} alt=""></img>
              <div className={styles.helpText}>
                <p>Note: the 6-digit code will renew every 30 seconds.</p>
              </div>
            </div>
          </div>
          <div className={styles.btnRow}>
            <Button
              color="attention"
              type="submit"
              onClick={() => {
                setMfaHelpDisplay(false)
                captureTelemetry("Clicked (Done) to go back to Login")
              }}
              text="Done"
              id="mfa_help_back_button"
              fullWidth
              title={"Button"}
              size="large"
            />
          </div>
        </Card>
      )}
      {layout === "mobile" && (
        <>
          {smallCardIndex === 1 && (
            <div className={styles.smallCardMobile}>
              <Title text="Step 01" />
              <div className={styles.imgContainer}>
                <img src="/img/mobile_connect_icon.png" className={styles.mcIcon} id="mfa_help_mobile_img_1" aria-hidden={true} alt=""></img>
              </div>
              <p>Open the mobile connect App on your mobile device.</p>
              <div className={styles.steps}>
                <span className={[styles.dot, styles.dotFilled].join(" ")}></span>
                <span className={styles.dot}></span>
                <span className={styles.dot}></span>
              </div>
              <div className={styles.btnRow}>
                <Button color="attention" onClick={() => setSmallcardIndex(2)} text="Next" id="mfa_help_mobile_next_button" fullWidth title={"Button"} size={"large"} />
                <Button color="secondary" onClick={() => setMfaHelpDisplay(false)} text="Back" id="mfa_help_mobile_back_button" fullWidth size={"large"} />
              </div>
            </div>
          )}
          {smallCardIndex === 2 && (
            <div className={styles.smallCardMobile}>
              <Title text="Step 02" />
              <div className={styles.imgContainer}>
                <img src="/img/mobile_connect_code.png" className={styles.mcCode} aria-hidden={true} alt=""></img>
              </div>
              <p>Under the &quot;cards&quot; tab you will find a 6-digit code. Enter in this code when prompted by the web admin client.</p>
              <div className={styles.steps}>
                <span className={styles.dot}></span>
                <span className={[styles.dot, styles.dotFilled].join(" ")}></span>
                <span className={styles.dot}></span>
              </div>
              <div className={styles.btnRow}>
                <Button color="attention" onClick={() => setSmallcardIndex(3)} text="Next" id="mfa_help_mobile_next_button" fullWidth title={"Button"} size={"large"} />
                <Button color="secondary" onClick={() => setSmallcardIndex(1)} text="Back" id="mfa_help_mobile_back_button" fullWidth size={"large"} />
              </div>
            </div>
          )}
          {smallCardIndex === 3 && (
            <div className={styles.smallCardMobile}>
              <Title text="Step 03" />
              <div className={styles.imgContainer}>
                <img src="/img/mobile_connect_clock.png" className={styles.mcClock} aria-hidden={true} alt=""></img>
              </div>
              <p>Note: the 6-digit code will renew every 30 seconds.</p>
              <div className={styles.steps}>
                <span className={styles.dot}></span>
                <span className={styles.dot}></span>
                <span className={[styles.dot, styles.dotFilled].join(" ")}></span>
              </div>
              <div className={styles.btnRow}>
                <Button color="attention" onClick={() => setMfaHelpDisplay(false)} text="Done" id="mfa_help_mobile_done_button" fullWidth title={"Button"} size={"large"} />
                <Button color="secondary" onClick={() => setSmallcardIndex(2)} text="Back" id="mfa_help_mobile_back_button" fullWidth size={"large"} />
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}
export default MfaHelp
