import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CardholderWithThumbnail } from "../../common/types/api.cardholder.type"
import { Results } from "../../common/types/api.search.type"
import { RootState } from "../../store/store"
import { CardholderSimpleSearchFilter } from "./cardholderSimpleSearch.type"

export type ApiError = "FAILED_TO_SEARCH" | "FAILED_TO_LOAD_MORE"
export type QueryError = "QUERY_CONTAINS_SPECIAL_CHARS"
export type Error = ApiError | QueryError

/**
 * Max number of cardholders to return per page.
 */
const DEFAULT_TOP = 20

export interface CardholderSearchState {
  hasDoneInitialSearch: boolean
  results: Results<CardholderWithThumbnail>

  /**
   * The filter to search with
   */
  filter?: CardholderSimpleSearchFilter

  /**
   * The text inside the search bar
   */
  query: string

  // REST API query params
  sort: string
  top: number

  queryError?: QueryError
  apiError?: ApiError
}

export const initialState: CardholderSearchState = {
  hasDoneInitialSearch: false,
  results: { results: [] },

  query: "",
  sort: "name",
  top: DEFAULT_TOP
} as const

export const cardholderSearchSlice = createSlice({
  name: "cardholderSearch",
  initialState: initialState,
  reducers: {
    queryChanged(state, action: PayloadAction<string>) {
      cardholderSearchSlice.caseReducers.resetApiErrors(state)

      state.query = action.payload
      state.top = initialState.top
      state.queryError = undefined

      if (state.query !== "" && /[&|]/.test(state.query)) {
        state.results = initialState.results
        state.apiError = undefined
        state.queryError = "QUERY_CONTAINS_SPECIAL_CHARS"
      }
    },
    filterChanged(state, action: PayloadAction<CardholderSimpleSearchFilter>) {
      state.filter = action.payload
    },
    searchSubmitted(state) {
      cardholderSearchSlice.caseReducers.resetApiErrors(state)
      state.hasDoneInitialSearch = true
    },
    searchDone(state, action: PayloadAction<Results<CardholderWithThumbnail> | undefined>) {
      cardholderSearchSlice.caseReducers.resetApiErrors(state)
      state.results = action.payload ?? initialState.results
    },
    searchFailed(state) {
      state.results = initialState.results
      state.apiError = "FAILED_TO_SEARCH"
    },
    loadMoreDone(state, action: PayloadAction<Results<CardholderWithThumbnail> | undefined>) {
      cardholderSearchSlice.caseReducers.resetApiErrors(state)
      if (action.payload) {
        state.results.results.push(...action.payload.results)
        state.results.next = action.payload.next
      }
    },
    loadMoreFailed(state) {
      state.apiError = "FAILED_TO_LOAD_MORE"
    },
    resetApiErrors(state) {
      state.apiError = undefined
    },
    removeCardholder(state, action: PayloadAction<string>) {
      state.results.results = state.results.results.filter((r) => r.id !== action.payload)
    }
  }
})

export const selectHasMoreResults = (state: RootState) => {
  return !!state.cardholderSearch.results.next?.href
}

export default cardholderSearchSlice.reducer

export const { searchSubmitted, searchDone, searchFailed, loadMoreDone, loadMoreFailed, queryChanged, filterChanged, removeCardholder } = cardholderSearchSlice.actions

export const selectResults = (state: RootState) => state.cardholderSearch.results
export const selectQuery = (state: RootState) => state.cardholderSearch.query
export const selectFilter = (state: RootState) => state.cardholderSearch.filter
export const selectApiError = (state: RootState) => state.cardholderSearch.apiError
export const selectQueryError = (state: RootState) => state.cardholderSearch.queryError
export const selectTop = (state: RootState) => state.cardholderSearch.top
export const selectSort = (state: RootState) => state.cardholderSearch.sort
export const selectHasDoneInitialSearch = (state: RootState) => state.cardholderSearch.hasDoneInitialSearch
