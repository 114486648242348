import { Icon, IconType } from "@ggl/components"
import Link from "next/link"
import React from "react"
import styles from "./nav.module.scss"

export interface NavItemProps {
  title: string
  href: string
  icon?: IconType
  active?: boolean
  onClick?: (href: string) => void
}

const NavItem = ({ title, href, icon, active, onClick }: NavItemProps) => {
  const clickHandler = (e: React.PointerEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    onClick?.(href)
  }

  return (
    <li className={`${styles.item} ${active ? styles.active : ""}`}>
      <Link className={styles.link} href={href} onClick={clickHandler} scroll={false}>
        <Icon type={"nav-cardholders"} className={styles.item_icon} label={title} />
        <span className={styles.title}>{title}</span>
      </Link>
    </li>
  )
}

export default NavItem
