import { Button, Modal, TextField } from "@ggl/components"
import posthog from "posthog-js"
import { useEffect, useRef, useState } from "react"
import invariant from "../../common/utils/invariant"
import { captureTelemetry } from "../../common/utils/postHog"
import { setBanner, useAppDispatch, useAppSelector } from "../../store"
import styles from "./Feedback.module.scss"

const Labels = {
  Title: "Feedback",
  Cancel: "Cancel",
  Submit: "Submit",
  Email: "Email (Optional)",
  Rate: "How would you rate your overall experience with Command Centre Web?",
  Comment: "Please share your feedback. The more detail, the better.",
  Poor: "Poor",
  Great: "Great",
  Success: "Thank you for submitting. Your feedback is very important to us to give you the best experience.",
  Error: "There has been an error sending your feedback, please try again later."
} as const

const Constraints = {
  EmailMaxLength: 50,
  CommentMaxLength: 500
} as const

const Levels = [1, 2, 3, 4, 5]
interface Feedback {
  apiKey: string
  siteId?: string
  email?: string
  rating?: (typeof Levels)[number]
  feedback?: string
  telemetryId: string
}

// CC Cloud endpoints
export const FEEDBACK_URL = "/api/webclient/feedback"
export const FEEDBACK_TOKEN = "3E9mb8XV:=ULpK9vcTT]XcvXQKVkMC9W"

const Feedback = ({ onClose }: { onClose?: (isSubmitted?: boolean) => void }) => {
  //redux
  const siteId = useAppSelector((state) => state.session.session?.siteGlobalId)
  const dispatch = useAppDispatch()
  // elements
  const form = useRef<HTMLFormElement>(null)
  // ui state
  const [state, setState] = useState<Feedback>({
    apiKey: FEEDBACK_TOKEN ?? "",
    telemetryId: posthog?.sessionManager?.checkAndGetSessionAndWindowId().sessionId ?? "",
    siteId
  })
  const [isValid, setIsValid] = useState<boolean>(false)

  useEffect(() => {
    if (form.current) {
      setIsValid(form.current?.checkValidity())
    }
  }, [state])

  const submitFeedback = async () => {
    try {
      invariant(window.CloudUrl)
      invariant(FEEDBACK_URL)
      await fetch(window.CloudUrl.concat(FEEDBACK_URL), {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(state)
      })

      dispatch(setBanner({ color: "success", message: Labels.Success }))
      onClose?.()
      captureTelemetry("Clicked (Submit) Feedback")
    } catch (error) {
      dispatch(setBanner({ color: "destructive", message: Labels.Error }))
      captureTelemetry("Clicked (Submit) Feedback", { error: (error as Error)?.message })
    }
  }

  const handleCancel = () => {
    onClose?.()
    captureTelemetry("Clicked (Cancel) Feedback")
  }

  const actions = () => {
    return [
      <Button onClick={handleCancel} className={styles.btn} color="secondary" key="feedback-cancel">
        {Labels.Cancel}
      </Button>,
      <Button color="primary" onClick={submitFeedback} disabled={!isValid} className={styles.btn} key="feedback-primary">
        {Labels.Submit}
      </Button>
    ]
  }

  return (
    <Modal actions={actions()} opaque title={Labels.Title}>
      <form ref={form} className={styles.container}>
        <TextField
          label={Labels.Email}
          type="email"
          value={state.email}
          maxLength={Constraints.EmailMaxLength}
          onChange={(e) => {
            setState({ ...state, email: e.target.value })
          }}
        />
        <div className={styles.levels}>
          <label>
            {Labels.Rate}
            <span className={styles.required_notice}>*</span>
          </label>
          <div className={styles.level_notice}>
            <div>{Labels.Poor}</div>
            <div>{Labels.Great}</div>
          </div>
          <ul
            onClick={(e) => {
              const element = e.target as HTMLElement
              if (element.tagName === "LI") {
                const rating = parseInt(element.textContent || "")
                setState({ ...state, rating })
              }
            }}
          >
            {Levels.map((l, i) => (
              <li key={i} tabIndex={0} className={l === state.rating ? styles.selected : ""}>
                {l}
              </li>
            ))}
          </ul>
          <input type="text" required value={state.rating ?? ""} onChange={() => {}} title="hidden" className={styles.hidden_input} />
        </div>
        <div>
          <label htmlFor="feedback_comment">
            {Labels.Comment}
            <span className={styles.required_notice}>*</span>
          </label>
          <textarea
            required
            id="feedback_comment"
            className={styles.textarea}
            placeholder="Type here..."
            value={state.feedback ?? ""}
            maxLength={Constraints.CommentMaxLength}
            onChange={(e) => {
              setState({ ...state, feedback: e.target.value })
            }}
          />
        </div>
      </form>
    </Modal>
  )
}

export default Feedback
