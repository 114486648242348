import { DEFAULT_THEME, getThemeInLocalStorage, setTheme, setupThemeListener, Theme } from "common/utils/theme.utils"
import { useDebouncedCallback } from "common/utils/useDebounce"
import React, { useEffect, useState } from "react"
import { useGetOperatorSettingsQuery } from "store/api.slice"
import { selectOperatorSettingsUrl, useAppSelector } from "../../store"
import Banner from "./Banner"
import EarlyAccessBanner from "./EarlyAccessBanner"
import styles from "./main.module.scss"

const Main = ({ children }: { children?: React.ReactNode }) => {
  const [extraCss, setExtraCss] = useState<string>("")

  const debouncedSetTheme = useDebouncedCallback(setTheme, 100)
  const getOperatorSettingsUrl = useAppSelector(selectOperatorSettingsUrl)
  const operatorSettingsResult = useGetOperatorSettingsQuery({ url: getOperatorSettingsUrl, fields: ["ccweb.general.theme"] }, { skip: !getOperatorSettingsUrl })

  // todo: move this to layout component once dark mode is permanently enabled
  // set the theme on first load
  useEffect(() => {
    debouncedSetTheme?.(getThemeInLocalStorage() ?? DEFAULT_THEME)
    setupThemeListener()
  }, [debouncedSetTheme])

  useEffect(() => {
    if (!operatorSettingsResult.data || !debouncedSetTheme) return

    // retrieve the theme setting from the API result and update the UI theme
    const theme = operatorSettingsResult.data["ccweb.general.theme"]

    debouncedSetTheme(theme as Theme)
  }, [debouncedSetTheme, operatorSettingsResult.data])

  useEffect(() => {
    const observer = new MutationObserver((mutationList) => {
      mutationList
        .filter((mutation) => mutation.type === "childList")
        .forEach(() => {
          const breadcrumbElement = document.querySelector("#breadcrumb-portal")
          setExtraCss(breadcrumbElement?.hasChildNodes() ? styles.hasBreadcrumb : "")
        })
    })
    observer.observe(document.querySelector("#breadcrumb-portal")!, { childList: true })

    return () => observer.disconnect()
  }, [])

  return (
    <section className={`${styles.scroll} ${extraCss}`.trim()}>
      <EarlyAccessBanner />
      <Banner />
      <main className={styles.main} role="main">
        {children}
      </main>
    </section>
  )
}

export default Main
