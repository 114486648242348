export function omitUrl(url: string) {
  if (url && typeof url === "string" && (/https?:\/\//i.test(url) || url.includes("gallagher.io"))) {
    
    const qIndex = url.indexOf("?") === -1 ? url.length : url.indexOf("?")

    //remove the client key from url
    const path = url.slice(0, qIndex).replace(/(https?:\/\/)?([\w|\*]+)(\..*)/i, (match, p1, p2, p3) => (p1 ?? "") + (p2.length >= 8 ? p2.slice(0, 8).concat("*") : "*") + p3)
    
    //remove qurery param values
    const params = new URLSearchParams(url.slice(qIndex))
    params.forEach((v, k) => {
      params.set(k, "".padStart(v.length, "*"))
    })
    
    return `${path}${qIndex !== url.length ? "?" + params.toString() : ""}`
  }
  return url
}