import React from "react"
// This function creates a hook that tracks the previous value of a variable
function usePrevious<T>(value: T) {
  // a standard ref hook
  const ref = React.useRef<T>()
  React.useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}
export default usePrevious
