import type { AppProps } from "next/app"
// app
import "../common/utils/wdyr"
import React, { useEffect } from "react"
import Layout from "../components/Layout"
import Head from "next/head"
// posthog
import posthog from "posthog-js"
import { omitUrl } from "../common/utils/omitUrl"
// redux
import { Provider } from "react-redux"
import { store } from "../store/store"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import responsiveDetect from "../store/responsiveDetect"
// styles
import "@ggl/components/dist/index.css"
import "../common/styles/main.scss"

type PostHogProperties = [{ key: string; value: string; operator: string }]

let persistor = persistStore(store)

function CCWeb({ Component, pageProps, router }: AppProps) {
  useEffect(() => {
    window?.addEventListener("resize", responsiveDetect)
    // trigger immediately so redux is in sync
    responsiveDetect() // this updates redux with the size of the viewport

    return () => {
      window?.removeEventListener("resize", responsiveDetect)
    }
  }, [])

  useEffect(() => {
    //Avoid loading posthog on CI automation tests
    if (!window.PostHogToken) return

    //Monitor fetch urls and force posthog heatmap using omitted url to fetch events
    const { fetch: originalFetch } = window
    const escapeRegex = (str: string): string => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1")
    window.fetch = async (...args) => {
      let [resource, config] = args
      if (typeof resource === "string" && (resource as string).startsWith("https://app.posthog.com/api/element/stats/?properties=")) {
        var url = new URL(resource.toString())
        const properties_str = url.searchParams.get("properties")
        if (properties_str) {
          const properties: PostHogProperties = JSON.parse(properties_str) as unknown as PostHogProperties
          const current_url = properties.find((p) => p.key === "$current_url")
          if (current_url) {
            current_url.value = `^${omitUrl(current_url?.value)
              .replace(/\w{8}\*/g, "*")
              .split(/\*+/)
              .map(escapeRegex)
              .join(`\\*+`)}$`
            current_url.operator = "regex"
          }
          url.searchParams.set("properties", JSON.stringify(properties))
          resource = url.toString()
        }
      }
      return await originalFetch(resource, config)
    }

    // Init PostHog
    posthog.init(window.PostHogToken, {
      api_host: "https://app.posthog.com",
      disable_session_recording: true,
      mask_all_text: true,
      sanitize_properties: (props, e) => {
        for (const [key, value] of Object.entries(props)) {
          props[key] = omitUrl(value)
        }
        return props
      }
    })

    // Track page views
    const handleRouteChange = () => posthog.capture("$pageview")
    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
      window?.removeEventListener("resize", responsiveDetect)
    }
  }, [router.events])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Head>
          <title>Command Centre Web</title>
          <link rel="icon" href="/favicon.ico" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        </Head>
        <Layout>
          <Component key={router.route} {...pageProps} />
        </Layout>
      </PersistGate>
    </Provider>
  )
}

export default CCWeb
