/**
 * Combine an URL with a set of query parameters.
 *
 * NOTE: query strings allow the same parameter to be repeated, but this helper doesn't support that
 * @param url absolute or relative URL
 * @param values a JS object which has one or more things to append
 * @returns
 */
export function appendQueryString(url: string, values: { [key: string]: string | boolean | number | undefined }): string {
  let u: URL
  let isRelative
  try {
    u = new URL(url)
    isRelative = false
  } catch {
    u = new URL(`https://placeholder.com/${url.replace(/^\//, "")}`) // append a fake host and protocol to make URL parsing work
    isRelative = true
  }

  for (let [k, v] of Object.entries(values)) {
    // value might not be there; in which case skip this entry
    if (v === undefined) continue

    // special case: number query value of 0 is falsy but we don't want to skip it
    if (typeof v === "number" || v) {
      u.searchParams.set(k, v.toString())
    }
  }

  return isRelative ? u.toString().replace(/^https:\/\/placeholder.com/, "") : u.toString()
}
