import { CustomError, ErrorList } from "./errors"
// This is basically a modified version of the tiny-invariant library found at: https://github.com/alexreardon/tiny-invariant
// I've merged it with the Custom Error object and error list to utilise a controlled message list
// The error message list should be hooked upto an internationalisation handler in the future and return messages in set languages
// This will do an assetion using a truthy check on a supplied condition or object and throw an error if its isnt met
export default function invariant(condition: any, error: ErrorList = ErrorList.Unknown): asserts condition {
  if (condition) {
    return
  }
  throw new CustomError(error)
}
