import { v4 as uuidv4 } from "uuid"

const DB_NAME = "db"
const DB_VERSION = 1
const TABLE_LOCK_REQUESTS = "lockRequests"

interface Lock {
  name: string
  lockedBy: string
  lockedAt: number
}

// Use IndexedDB for recording locks for transaction support
var indexedDb: IDBDatabase
var instanceId = uuidv4()

function getDatabase(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    if (indexedDb) {
      resolve(indexedDb)
      return
    }

    var openRequest = window.indexedDB.open(DB_NAME, DB_VERSION)

    openRequest.onerror = (event) => {
      console.error("Failed to load database for lockManager!")
      reject(event)
    }

    openRequest.onsuccess = () => {
      indexedDb = openRequest.result
      resolve(indexedDb)
    }

    openRequest.onupgradeneeded = () => {
      const db = openRequest.result

      db.onerror = (event) => {
        console.error("Failed to load database for lockManager!")
        reject(event)
      }

      var store = db.createObjectStore(TABLE_LOCK_REQUESTS, { keyPath: "name" })
      store.createIndex("lockedBy", "lockedBy", { unique: false })
      store.createIndex("lockedAt", "lockedAt", { unique: false })
    }
  })
}

export async function tryGetLock(name: string, lockDuration: number): Promise<string | null> {
  return new Promise(async (resolve, reject) => {
    const db = await getDatabase()

    const lockRequests = db.transaction(TABLE_LOCK_REQUESTS, "readwrite").objectStore(TABLE_LOCK_REQUESTS)
    const getRequest = lockRequests.get(name)

    getRequest.onsuccess = () => {
      var existingLock = getRequest.result && (getRequest.result as Lock)

      if (!existingLock || existingLock.lockedBy === instanceId || existingLock.lockedAt + lockDuration < Date.now()) {
        lockRequests.put({ name: name, lockedBy: instanceId, lockedAt: Date.now() })
        resolve(name)
      } else {
        resolve(null)
      }
    }

    getRequest.onerror = (ev) => {
      reject(ev)
    }
  })
}
