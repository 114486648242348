import createWebStorage from "redux-persist/lib/storage/createWebStorage"

// This is a fallback method incase we are running tests or compiling the project in an environment without global.window existing
export const createNoopStorage = () => {
  return {
    getItem(key: string) {
      return Promise.resolve(null)
    },
    setItem(key: string, value: string) {
      return Promise.resolve(value)
    },
    removeItem(key: string) {
      return Promise.resolve()
    }
  }
}
const storage = typeof window !== "undefined" && window.document ? createWebStorage("session") : createNoopStorage()
export default storage
