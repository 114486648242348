import type { MessageColors } from "@ggl/components"
import { createSlice, current, PayloadAction } from "@reduxjs/toolkit"
import features from "../common/features"
import { Link } from "../common/types/api.link.type"
import Session from "../common/types/api.session.type"
import type { Features } from "../common/types/features.type"

export type Layout = "desktop" | "mobile"
type Banner = {
  color?: MessageColors
  message: string
  timeout?: number
}
interface SessionInitialState {
  session?: Session
  thumbnail?: string
  expiry?: number
  drawerClosed: boolean
  platform: string | "web" | "mobile"
  layout?: Layout
  banner?: Banner
  timestamp: string //Timestamp when cardholder is updated
  features: {
    features: Features
    flags?: Array<string> // a pre-parsed list of the current active flags
  }
  operator?: {
    settings: Link & {
      update: Link
    }
  }
}

/**
 * This parses a feature flag file and produces a string array of what is active so we can cache it instead of looping over the file everytime.
 *
 * @param features This is a the feature flag definition for the site.
 * @returns A string array of active features.
 */
export const parseFeatures = (features?: Features, siteId?: string) => {
  if (!features || typeof window === "undefined") return [] // the site should fallback to an all features off state if we don't have a features files declared
  return Object.entries(features)
    .filter(([_, feature]) => {
      // remove any which the user has toggled 'off'
      return feature.isEnabled || feature.filters
    })
    .filter(([_, feature]) => {
      if (feature.isEnabled) return feature.isEnabled
      return feature.filters?.some((f) => {
        if ("ids" in f) return !(!f.ids.includes(siteId!) || ("env" in f && !f.env?.includes(window.ENV)))
        // else lets just check the env list
        if ("env" in f) return f.env?.includes(window.ENV)
      })
    })
    .map(([name, _]) => {
      return name
    })
}

export const sessionInitialState: SessionInitialState = {
  drawerClosed: true,
  platform: "web",
  layout: typeof window !== "undefined" && window.innerWidth > 860 ? "desktop" : "mobile",
  timestamp: Date.now().toString(),
  features: {
    features: features,
    flags: undefined // this is set in setSession
  }
}

export const sessionSlice = createSlice({
  name: "session",
  initialState: sessionInitialState,
  reducers: {
    setSession: (state, action: PayloadAction<Session>) => {
      state.session = action.payload
      if (state.features.flags === undefined) {
        state.features.flags = parseFeatures(current(state.features.features), action.payload.siteGlobalId)
      }
    },
    setExpiry: (state, action: PayloadAction<number>) => {
      state.expiry = action.payload
    },
    setPlatform: (state, action: PayloadAction<string>) => {
      state.platform = action.payload
    },
    setDrawerClosed: (state, action: PayloadAction<boolean>) => {
      state.drawerClosed = action.payload
    },
    setBanner: (state, action: PayloadAction<Banner | undefined>) => {
      state.banner = action?.payload
    },
    clearBanner: (state) => {
      state.banner = undefined
    },
    setTimestamp: (state) => {
      state.timestamp = Date.now().toString()
    },
    setLayout: (state, action: PayloadAction<{ layout: Layout }>) => {
      if (action.payload.layout === state.layout) return
      state.layout = action.payload.layout
    },
    resetFeatures: (state) => {
      state.features.features = features
      state.features.flags = parseFeatures(features, state.session?.siteGlobalId)
    },
    setFeature: (state, action: PayloadAction<{ name: keyof Features; state: boolean }>) => {
      if (state.features.flags === undefined) state.features.flags = []
      if (state.features.features[action.payload.name]) {
        if (action.payload.state && !state.features.flags.includes(action.payload.name)) {
          state.features.flags.push(action.payload.name)
        } else if (!action.payload.state && state.features.flags.includes(action.payload.name)) {
          const i = state.features.flags.indexOf(action.payload.name)
          state.features.flags.splice(i, 1)
        }
      }
    },
    setFeatures: (state, action: PayloadAction<boolean>) => {
      state.features.flags = action.payload ? (Object.keys(state.features.features) as (keyof Features)[]) : ["config" as keyof Features]
    }
  }
})

export const { setSession, setExpiry, setPlatform, setDrawerClosed, setBanner, clearBanner, setTimestamp, setLayout, setFeature, setFeatures, resetFeatures } = sessionSlice.actions

export default sessionSlice.reducer
