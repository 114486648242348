import { Button, Card, Logo, MessageBar, PasswordField, TextField } from "@ggl/components"
import { getSystemPreferredTheme, setTheme, Theme } from "common/utils/theme.utils"
import { useDebouncedCallback } from "common/utils/useDebounce"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLazyGetOperatorSettingsQuery } from "store/api.slice"
import { WebClientErrorCode } from "../../common/errorCodes"
import { Link } from "../../common/types/api.link.type"
import Session from "../../common/types/api.session.type"
import { ApiError, JsonError } from "../../common/utils/apiCall"
import { ErrorList } from "../../common/utils/errors"
import invariant from "../../common/utils/invariant"
import { captureTelemetry } from "../../common/utils/postHog"
import { registerSessionPoller } from "../../common/utils/sessionPoller"
import { setRefreshInfo } from "../../common/utils/sessionRefreshInfo"
import { CC_WEB_VERSION } from "../../common/utils/version"
import { setSession, useAppDispatch } from "../../store"
import { ExpiryPopupStorageKey } from "../ExpiryPopup/ExpiryPopup"
import ChangePasswordDialog from "./../ChangePassword"
import { PasswordRestrictions } from "./../ChangePassword/apicalls"
import * as api from "./apicalls"
import styles from "./loginForm.module.scss"
import MfaHelp from "./mfaHelp"

const SITE_NOT_LICENSED = "Access Denied: Your account does not have an active subscription. Please contact your Gallagher Channel Partner to set up your subscription."
const OPERATOR_NOT_LICENSED = "Access Denied: You do not have a license to use this service under the current subscription. Please contact your administrator or support to request access."
const LICENSING_SERVICE_UNAVAILABLE = "Error while checking license. Please try again later."

// if the operator logs in and is required to change their password, the server will respond
// with one of these
interface LogonChangePasswordError extends JsonError {
  changeOperatorPassword: PasswordRestrictions & {
    updatePassword: Link
  }
}

interface LoginFormProps {
  checkSession?: () => void
}

const browserLocale = Intl.DateTimeFormat().resolvedOptions().locale
const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

const LoginForm = ({ checkSession }: LoginFormProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [capsLockOn, setCapsLockOn] = useState(false)
  const [password, setPassword] = useState<string>("")
  const [userName, setUserName] = useState<string>("")
  const [mfaCode, setMfaCode] = useState<string>("")
  const [apiError, setApiError] = useState<string>("")
  const dispatch = useAppDispatch()
  // forced-change-password when logging on
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [logonChangePasswordError, setLogonChangePasswordError] = useState<LogonChangePasswordError>()
  //Mfa code input and help page display
  const [mfaHelpDisplay, setMfaHelpDisplay] = useState(false)
  const [mfaInputDisplay, setMfaInputDisplay] = useState(false)
  const mfaInputsRef = useRef<any[]>([])
  const focusInputRef = useRef(0)
  const mfaLength = 6
  const KEYCODE = Object.freeze({
    LEFT_ARROW: 37,
    RIGHT_ARROW: 39,
    END: 35,
    HOME: 36,
    SPACE: 32,
    BACK_SPACE: 8
  })
  const debouncedSetTheme = useDebouncedCallback(setTheme, 100)
  const [triggerGetOperatorSettings] = useLazyGetOperatorSettingsQuery()

  // clear the expiry popup session storage when login
  useEffect(() => {
    window.sessionStorage.removeItem(ExpiryPopupStorageKey)
  }, [])

  //use to solve Chrome autofill bug
  const [inputKey, setInputKey] = useState("")

  const userNameOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value)
  }

  const passwordOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  //Region begin: mfa code functions
  const isInputNumber = useCallback((value: string) => {
    return /^[0-9]*$/.test(value)
  }, [])

  const focusInput = useCallback((i: number) => {
    const inputs = mfaInputsRef.current
    if (i >= inputs.length) return
    const input = inputs[i]
    if (!input) return
    input.focus()
    focusInputRef.current = i
  }, [])

  const focusNextInput = useCallback(() => {
    const curFoncusIndex = focusInputRef.current
    const nextIndex = curFoncusIndex + 1 >= mfaLength ? mfaLength - 1 : curFoncusIndex + 1
    focusInput(nextIndex)
  }, [focusInput])

  const focusPrevInput = useCallback(() => {
    const curFoncusIndex = focusInputRef.current
    let prevIndex
    if (curFoncusIndex === mfaLength - 1 && mfaCode.length === mfaLength) {
      prevIndex = mfaLength - 1
    } else {
      prevIndex = curFoncusIndex - 1 <= 0 ? 0 : curFoncusIndex - 1
    }
    focusInput(prevIndex)
  }, [focusInput, mfaCode])

  const mfaCodeOnDeleteHandler = useCallback(() => {
    const curIndex = focusInputRef.current
    if (curIndex === 0) {
      if (!mfaCode) return
      setMfaCode("")
    } else if (curIndex === mfaLength - 1 && mfaCode.length === mfaLength) {
      setMfaCode(mfaCode.slice(0, curIndex))
    } else {
      setMfaCode(mfaCode.slice(0, mfaCode.length - 1))
    }
    focusPrevInput()
  }, [focusPrevInput, mfaCode])

  const mfaCodeOnKeyDownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      switch (e.keyCode) {
        case KEYCODE.LEFT_ARROW:
          break
        case KEYCODE.RIGHT_ARROW:
          break
        case KEYCODE.HOME:
          break
        case KEYCODE.END:
          break
        case KEYCODE.SPACE:
          e.preventDefault()
          break
        case KEYCODE.BACK_SPACE:
          mfaCodeOnDeleteHandler()
          break
        default:
          break
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mfaCodeOnDeleteHandler]
  )

  const mfaCodeOnChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value
      if (!isInputNumber(val)) return
      if (val.length === 1) {
        focusNextInput()
        setMfaCode(`${mfaCode}${val}`)
      }
    },
    [focusNextInput, isInputNumber, mfaCode]
  )

  const mfaCodeOnClickHandler = useCallback(() => {
    focusInput(focusInputRef.current)
  }, [focusInput])

  //Region end: mfa code functions
  const isCapsLockOn = (e: React.KeyboardEvent) => {
    if (e.getModifierState("CapsLock")) {
      return setCapsLockOn(true)
    } else {
      setCapsLockOn(false)
    }
  }

  const isInputEmpty = () => {
    if (!userName && !password) {
      setApiError("Username and password must be entered")
      return true
    } else if (!userName) {
      setApiError("Username must be entered")
      return true
    } else if (!password) {
      setApiError("Password must be entered")
      return true
    } else {
      setApiError("")
      return false
    }
  }

  const goNext = () => {
    if (!isInputEmpty()) {
      captureTelemetry("Clicked (Next) to enter MFA")
      setMfaInputDisplay(true)
      focusInput(0)
    }
    setInputKey("userinfo")
  }

  const onNext = (e: React.SyntheticEvent) => {
    e.preventDefault()
  }

  // If you want to run this as a developer, then you need to modify env_vars.js and restart yarn
  const getEntryPointUrl = () => {
    // we might in future add code here to look at window.location.hostname
    // and if we detect we're running on a well-known URL, force override the gateway entrypoint
    // because we know what it should be. For now though always go through env_vars.js
    return window.GatewayEntryPointUrl ?? "https://localhost:8903/api/session" // fallback if for some reason it's not there, e.g. unit tests
  }

  const setUiTheme = useCallback(
    async (url: string) => {
      try {
        // trigger the API call to get operator settings
        const result = await triggerGetOperatorSettings({
          url,
          fields: ["ccweb.general.theme"]
        }).unwrap()

        // if the result contains the theme field, update the theme
        if (result && result["ccweb.general.theme"]) {
          debouncedSetTheme(result["ccweb.general.theme"] as Theme)
        }
      } catch (error) {
        console.error("Failed to fetch operator settings:", error)
      }
    },
    [triggerGetOperatorSettings, debouncedSetTheme]
  )

  const proceedAfterLogin = useCallback(
    async (response: Session) => {
      const operatorSettingsUrl = response?.operator?.settings?.href ?? ""
      if (operatorSettingsUrl) await setUiTheme(operatorSettingsUrl)

      dispatch(setSession(response))

      if (response.refresh) {
        const refreshIntervalMs = response.refresh.interval * 1000
        setRefreshInfo({
          refreshToken: response.refresh.refreshToken,
          refreshDueTime: Date.now() + refreshIntervalMs
        })

        // start the session poller to refresh our authentication token while we are logged in
        registerSessionPoller(refreshIntervalMs)
      }
    },
    [dispatch, setUiTheme]
  )

  const doUpdatePassword = async (password: string, newPassword: string): Promise<boolean> => {
    // repeat the logon, but supplying a new password
    if (!logonChangePasswordError) {
      throw new ApiError(400, "Internal Client Error: no info to update password")
    }
    const loginUpdatePasswordUrl = logonChangePasswordError.changeOperatorPassword.updatePassword.href
    // this will throw an ApiError if the server returns a 4xx or other error message
    try {
      const response = await api.login(loginUpdatePasswordUrl, userName, password, browserLocale, browserTimeZone, undefined, newPassword)

      await proceedAfterLogin(response)
      setShowChangePasswordModal(false)
      return false // tell the change password to immediately close itself, rather than proceeding to the "you did it!" screen
    } catch (err: any) {
      if ("httpStatusCode" in err && !("code" in err)) {
        // generic failure with no specific error code, it must be invalid password
        throw new ApiError(err.httpStatusCode, "Invalid current password", 1)
      }
      throw err
    }
  }

  const doLogon = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) => {
      checkSession?.()
      e.preventDefault()
      if (!isInputEmpty()) {
        setIsLoading(true)

        const entryPointUrl = getEntryPointUrl()
        invariant(entryPointUrl, ErrorList.NoURL)

        try {
          const response = await api.login(entryPointUrl, userName, password, browserLocale, browserTimeZone, mfaCode)
          await proceedAfterLogin(response)
          captureTelemetry("Successfully Logged in", { systemPreferredTheme: getSystemPreferredTheme() })
        } catch (err: any) {
          if ("httpStatusCode" in err) {
            const appErrorCode = "code" in err ? err.code : -1
            switch (err.httpStatusCode) {
              case 400: //this case is not reachable, will get 401 instead
                if (!userName && !password) setApiError("Username and password must be entered")
                else if (!userName) setApiError("Username must be entered")
                else if (!password) setApiError("Password must be entered")
                else setApiError(err.message)
                break
              case 401:
                switch (appErrorCode) {
                  case 16777576:
                    if ("changeOperatorPassword" in err && "code" in err) {
                      // password has expired, kick off the change password dialog
                      setLogonChangePasswordError(err)
                      setShowChangePasswordModal(true)
                    }
                    setApiError(err.message)
                    break
                  case -1056964607:
                    setApiError("Invalid username or password, please try again.")
                    setPassword("")
                    captureTelemetry("Unsuccessful Login (Username & Password)")
                    break
                  case -1044053810:
                    setApiError("Invalid authentication code, please try again.")
                    setMfaCode("")
                    focusInput(0)
                    captureTelemetry("Unsuccessful Login (MFA Code)")
                    break
                  case -1045299150:
                    setApiError("Operator locked out due to repeat logon failures")
                    setMfaCode("")
                    focusInput(0)
                    setMfaInputDisplay(false)
                    break
                  default:
                    // If the server gave us some kind of message, show the user
                    if (err.message) {
                      setApiError(err.message)
                    } else {
                      // else a generic fallback is the best we can do
                      setApiError("Your credentials were incorrect, please try again")
                      captureTelemetry("Unsuccessful Login (Other)")
                    }
                    break
                }
                break
              case 403:
                switch (appErrorCode) {
                  case WebClientErrorCode.SITE_NOT_LICENSED:
                    setApiError(SITE_NOT_LICENSED)
                    setMfaCode("")
                    captureTelemetry("Unsuccessful Login (Site not licensed)")
                    break
                  case WebClientErrorCode.OPERATOR_NOT_LICENSED:
                    setApiError(OPERATOR_NOT_LICENSED)
                    setMfaCode("")
                    captureTelemetry("Unsuccessful Login (Operator not licensed)")
                    break
                }
                break
              case 424:
                switch (appErrorCode) {
                  case WebClientErrorCode.LICENSING_SERVICE_UNAVAILABLE:
                    setApiError(LICENSING_SERVICE_UNAVAILABLE)
                    captureTelemetry("Unsuccessful Login (License check failed)")
                    break
                }
                break
              default:
                setApiError(err.message ?? "Your credentials were incorrect, please try again")
                break
            }
          }
          setIsLoading(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [proceedAfterLogin, password, userName, mfaCode]
  )

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (showChangePasswordModal) {
        return // modal deals wit it's own keyboard events
      }
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault()

        if (!mfaInputDisplay && !isInputEmpty()) {
          // If they press enter while the MFA box is not up, show that instead
          setMfaInputDisplay(true)
        } else {
          doLogon(event)
        }
      }
    }
    document.addEventListener("keydown", listener)
    return () => document.removeEventListener("keydown", listener)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mfaInputDisplay, doLogon, showChangePasswordModal])

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <Logo />
      </div>
      {showChangePasswordModal ? (
        <ChangePasswordDialog closeHandler={() => setShowChangePasswordModal(false)} passwordRestrictions={logonChangePasswordError?.changeOperatorPassword} updatePasswordHandler={doUpdatePassword} />
      ) : null}
      {!mfaHelpDisplay && (
        <Card className={styles.card}>
          <form className={styles.form} onSubmit={onNext}>
            {!mfaInputDisplay && (
              <div className={styles.welcomeContainer}>
                <h2 className={styles.welcome}>Welcome!</h2>
                <p className={styles.signIn}>Please sign in to Command Centre Web.</p>
              </div>
            )}
            <TextField
              autoCapitalize="off"
              placeholder="Username"
              label="Username"
              id="logon_name_textbox"
              disabled={isLoading}
              key={inputKey + "_u"}
              value={userName}
              onChange={userNameOnChangeHandler}
              onKeyPress={isCapsLockOn}
              size="large"
            />
            <PasswordField
              autoComplete="off"
              disabled={isLoading}
              placeholder="Password"
              id="logon_password_textbox"
              key={inputKey + "_p"}
              onChange={passwordOnChangeHandler}
              label="Password"
              value={password}
              onKeyPress={isCapsLockOn}
              size="large"
            />
            {mfaInputDisplay && (
              <>
                <div className={styles.mfaContainer}>
                  <h2 className={styles.mfaTitle}>Authentication</h2>
                  <p className={styles.mfaInstructions}>Please enter your authentication code found on your Gallagher Mobile Connect App.</p>
                </div>
                <div className={styles.mfaInputs}>
                  {Array.from({ length: mfaLength }).map((_, index) => {
                    const css = [styles.mfaInput]
                    if (index === 2) css.push(styles.gap)
                    return (
                      <input
                        id={"mfa_inputbox_" + index}
                        key={index}
                        ref={(ref) => {
                          mfaInputsRef.current[index] = ref
                        }}
                        className={css.join(" ")}
                        maxLength={1}
                        type="text"
                        inputMode="numeric"
                        autoComplete="false"
                        value={mfaCode[index] || ""}
                        onClick={mfaCodeOnClickHandler}
                        onChange={mfaCodeOnChangeHandler}
                        onKeyDown={mfaCodeOnKeyDownHandler}
                        autoFocus={index == 0 ? true : false}
                      />
                    )
                  })}
                </div>
                <a
                  onClick={() => {
                    setMfaHelpDisplay(!mfaHelpDisplay)
                    captureTelemetry("Clicked (How to find MFA)")
                  }}
                  href="#"
                  className={styles.help}
                  id="mfa_help_link"
                >
                  How to find my authentication code?
                </a>
              </>
            )}
            {apiError && (
              <MessageBar
                textId="logon_api_error_messagebar"
                text={apiError}
                color="destructive"
                className={styles.messageBar}
                onClick={() => {
                  setApiError("")
                }}
              />
            )}
            <div className={styles.btnRow}>
              {!mfaInputDisplay && <Button onClick={goNext} text="Next" id="show_mfa_button" fullWidth title={"Button"} loading={isLoading} color="attention" size={"large"} type="submit" />}
              {mfaInputDisplay && <Button onClick={doLogon} text="Sign in" id="logon_button" fullWidth title={"Button"} loading={isLoading} color="attention" size={"large"} type="submit" />}
            </div>
            {capsLockOn && <MessageBar text={"Caps lock is enabled"} color="attention" />}
            {CC_WEB_VERSION && (
              <div className={styles.version} data-testid="version">
                CC Web: {CC_WEB_VERSION}
              </div>
            )}
          </form>
        </Card>
      )}
      {mfaHelpDisplay && <MfaHelp setMfaHelpDisplay={setMfaHelpDisplay} />}
    </div>
  )
}

export default LoginForm
