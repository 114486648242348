export enum ErrorList {
  Unknown,
  NoURL,
  InvalidDate
}

export const NO_URL_ERROR = "Unexpected null entryPointUrl"
export const UNKNOWN_ERROR = "An unknown Error has occurred"
export const INVALID_DATE = "Invalid date"

// we can use this make sure we have multilingual messaging
// currently only english support, but this should be easily extensible
export class CustomError extends Error {
  constructor(error?: ErrorList) {
    let message: string
    switch (error) {
      case ErrorList.NoURL:
        message = NO_URL_ERROR
        break
      case ErrorList.InvalidDate:
        message = INVALID_DATE
        break
      case ErrorList.Unknown:
      default:
        message = UNKNOWN_ERROR
        break
    }
    super(message)
  }
}

export class TimeoutError extends Error {
  constructor() {
    super()
  }
}