import posthog from "posthog-js"
import { EventType, GroupType, SetUserProperty } from "../types/analytics.type"
import { CC_WEB_VERSION } from "../../common/utils/version"
import { store } from "../../store/store"
import { selectCommandCentreVersion } from "../../store"

/**
 * Initialize PostHog with the given token and config.
 * @param token This is the PostHog token, e.g. "phc_6C4mlcJs4sVoF3qaSsJJX5C3GTCnkx"
 * @param config This is the PostHog config, e.g. { api_host: "https://app.posthog.com" }
 * @param name This is the PostHog name, e.g. "LicensingService"
 */
export const initPostHog = (token: string, config?: object, name?: string) => {
  //Avoid loading posthog on CI automation tests
  if (!window.PostHogToken) return
  posthog.init(token, config ?? undefined, name ?? undefined)
}

/**
 * Capture a PostHog event with the given event type, properties, identifier, and group.
 * @param event This is the event type, e.g. "ButtonClicked"
 * @param properties This is the event properties, e.g. { buttonName: "Save" }
 * @param identifier This is the identifier for the user, e.g. "1542"
 * @param group This is the group for the user, e.g. { groupType: "CCVersion", groupKey: "8.90" }
 */
export const capturePostHogEvent = (
  event: EventType,
  properties: Record<string, unknown>,
  identifier?: string,
  group?: { groupType: GroupType; groupKey: string },
  setUserProperty?: SetUserProperty
) => {
  //Avoid loading posthog on CI automation tests
  if (!window.PostHogToken) return
  if (identifier) posthog.identify(identifier)
  if (group?.groupType && group?.groupKey) posthog.group(group.groupType, group.groupKey ?? "undefined")
  if (setUserProperty) {
    let userProperty: Record<string, any>
    if (setUserProperty.setOnce) {
      userProperty = { $set_once: setUserProperty.properties }
    } else {
      userProperty = { $set: setUserProperty.properties }
    }
    properties = { ...properties, ...userProperty }
  }
  posthog.capture(event, properties)
}

export const captureTelemetry = (name: EventType, customProperties?: Record<string, unknown>) => {
  const commandCentreVersion = selectCommandCentreVersion(store.getState())
  capturePostHogEvent(name, { page: window.location.href, commandCentreWebVersion: CC_WEB_VERSION, commandCentreVersion: commandCentreVersion, ...customProperties })
}
