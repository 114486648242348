const REFRESH_INFO_KEY = "refreshInfo"

export interface RefreshInfo {
  refreshToken: string // base64 encoded token to use for the session refresh request
  refreshDueTime: number // timestamp for when token refresh is due for the current session in seconds
}

export function getRefreshInfo() {
  const storedRefreshInfo = localStorage.getItem(REFRESH_INFO_KEY)
  if (!storedRefreshInfo) return null

  return JSON.parse(storedRefreshInfo) as RefreshInfo
}

export function setRefreshInfo(refreshInfo: RefreshInfo) {
  localStorage.setItem(REFRESH_INFO_KEY, JSON.stringify(refreshInfo))
}
