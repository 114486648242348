import { Poll } from "./Poll"
import { refreshSession } from "./sessionRefresh"
import { getRefreshInfo, setRefreshInfo } from "./sessionRefreshInfo"
import { tryGetLock } from "./lockManager"
import { store } from "../../store/store"
import invalidateSession from "../../store/invalidateSession"

export const MAX_REFRESH_POLL_INTERVAL_MS = 60000
export const POLL_TIMEOUT = 10000

export function registerSessionPoller(intervalMS: number) {
  if (!window) {
    return // No window in unit tests, don't use this poller in our tests
  }

  if (typeof window.sessionPoll === "object") {
    window.sessionPoll?.stop()
  }

  // Run the session refresh poll on a short interval to reduce the risk of missing a refresh
  const refreshPollIntervalMS = Math.min(intervalMS, MAX_REFRESH_POLL_INTERVAL_MS)

  window.sessionPoll = new Poll({
    intervalMS: refreshPollIntervalMS,
    timeoutMS: POLL_TIMEOUT,
    callback: async () => {
      const refreshInfo = getRefreshInfo()
      if (!refreshInfo) {
        console.warn("Couldn't get refresh info from local storage")
        return
      }

      // Check if we are due a refresh based on the last time we received an auth cookie
      if (Date.now() < refreshInfo.refreshDueTime) {
        return // not due for refresh yet
      }

      const lock = await tryGetLock("tokenRefreshLock", 6000)
      if (!lock) {
        return // did not receive exclusive lock, skipping this poll
      }

      try {
        const newRefreshToken = await refreshSession(refreshInfo.refreshToken)

        if (newRefreshToken) {
          // Session refresh succeeded, store the new refresh token for use in the next refresh request
          const interval = !!store.getState().session.session?.refresh?.interval ? store.getState().session!.session!.refresh!.interval * 1000 : refreshPollIntervalMS
          setRefreshInfo({ refreshToken: newRefreshToken, refreshDueTime: Date.now() + interval })
        }
      } catch (err: any) {
        if (err.hasOwnProperty("httpStatusCode")) {
          console.error("bad response from the server during session refresh: ", err.httpStatusCode)
        } else {
          console.error("unexpected error during session refresh: ", err.message)
        }
        invalidateSession()
      }
    },
    onTimeout: () => {
      window.sessionPoll?.stop()
    }
  })

  window.sessionPoll?.start()
}
