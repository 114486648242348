/**
 * Version returned in session has four parts but Command Centre version really only has three parts.
 */
export type VersionString = `${number}.${number}.${number}` | `${number}.${number}.${number}.${number}`

export const enum KnownCommandCentreVersion {
  /**
   * The version where we can filter cardholder search last access zone by id
   */
  GetCardholdersFilterByAccessZone = "9.10.000",
  /**
   * The version where we can check privileges using /api/divisions endpoint
   */
  GetDivisionsVerbAndFTItem = "8.90.128",
  FixedCredentialRemoveReason = "8.90.128",
  MinimumSupportedVersion = "8.80.000"
}

/**
 * Remove leading zeroes and ensure the version string has at least `numParts` parts
 * @param version - the version string
 * @param numParts - the minimum number of parts `version` should have
 * @returns the normalised version string
 */
const normalise = (version: VersionString, numParts: number): VersionString => {
  let copy: string = version

  // Make version have at least numParts
  while (copy.split(".").length < numParts) {
    copy += ".0"
  }

  // Remove leading zeroes
  copy = copy
    .split(".")
    .map((n) => parseInt(n, 10))
    .join(".")

  return copy as VersionString
}

/**
 * Compare two version strings.
 * @returns -1 if less than, 0 if equal, 1 if greater than
 */
const compare = (current: VersionString, other: VersionString) => {
  // Because "1.0.0".localeCompare("1.0.0.0", undefined, { numeric: true, sensitivity: "base" }) returns -1,
  // normalise the versions so that "1.0.0" is treated as equal to "1.0.0.0"
  // and "1.0.01" is treated as equal to "1.0.1"
  const maxParts = Math.max(...[current, other].map((v) => v.split(".").length))
  return normalise(current, maxParts).localeCompare(normalise(other, maxParts), undefined, { numeric: true, sensitivity: "base" })
}

const equals = (current: VersionString | undefined, other: VersionString) => {
  if (!current) return false
  return compare(current, other) === 0
}

const lessThan = (current: VersionString | undefined, other: VersionString) => {
  if (!current) return false
  return compare(current, other) === -1
}

const lessThanOrEqual = (current: VersionString | undefined, other: VersionString) => {
  if (!current) return false
  return compare(current, other) < 1
}

const greaterThan = (current: VersionString | undefined, other: VersionString) => {
  if (!current) return false
  return compare(current, other) === 1
}

const greaterThanOrEqual = (current: VersionString | undefined, other: VersionString) => {
  if (!current) return false
  return compare(current, other) > -1
}

export const ENV = ((global || window) as any).ENV
const CCWebVersion = ((global || window) as any).CCWebVersion
export const CC_WEB_VERSION = CCWebVersion?.split(".").at(0) ?? ""

const Version = { equals, lessThan, lessThanOrEqual, greaterThan, greaterThanOrEqual }
export default Version
