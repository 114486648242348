import { Banner as GGLBanner } from "@ggl/components"
import styles from "./main.module.scss"
import { useAppSelector, useAppDispatch, selectBanner, clearBanner } from "../../store"
const ShowingTime = 3000
export default function Banner() {
  const state = useAppSelector(selectBanner)
  const dispatch = useAppDispatch()
  const timeout = state?.timeout || (state?.color === "destructive" ? undefined : ShowingTime)
  const dismiss = () => {
    dispatch(clearBanner())
  }

  return <div className={styles.bannerContainer}>{state && <GGLBanner {...state} timeout={timeout} className={styles.banner} onDismiss={dismiss} id="main-banner" />}</div>
}
