const getPlatform = () => {
  // basically we do our best effort to figure out the platform we running on
  // where the Web Client launching from, this is not based on window dimensions
  // code based of this ref. https://stackoverflow.com/questions/19877924/what-is-the-list-of-possible-values-for-navigator-platform-as-of-today

  if (typeof window !== "undefined") {
    const platform = navigator.platform.toLowerCase()
    if (platform.startsWith("iphone") || platform.startsWith("ipod") || platform.startsWith("android")) return "mobile"
  }

  return "web"
}

export default getPlatform
