import { store } from "../../store/store"
import { post } from "../../common/utils/apiCall"

export async function refreshSession(refreshToken: string): Promise<string | null> {
  const session = store.getState().session.session
  const refreshHref = session?.refresh?.href
  if (!session || !refreshHref) {
    console.warn("refresh info is missing for this session - cannot perform token refresh")
    return null
  }

  // Initial session refresh request
  const challengeResponse = await post<SessionRefreshChallenge>(refreshHref, { refreshToken: refreshToken })

  if (challengeResponse.algorithm != "HmacSHA256") {
    console.warn("session refresh failed - unsupported session refresh algorithm specified by the server: ", challengeResponse.algorithm)
    return null
  }

  const algorithm = { name: "HMAC", hash: { name: "SHA-256" } }
  const hmacKey = await crypto.subtle.importKey("raw", Buffer.from(refreshToken, "base64"), algorithm, false, ["sign", "verify"])
  const signature = await crypto.subtle.sign(algorithm.name, hmacKey, Buffer.from(challengeResponse.challenge, "base64"))

  const refreshResponse = await post<SessionRefreshResponse>(refreshHref, {
    challenge: challengeResponse.challenge,
    signature: Buffer.from(signature).toString("base64"),
    refreshToken: refreshToken
  })

  if (!refreshResponse.refreshToken) {
    console.warn("session refresh failed - the server didnt provide us with a new refresh token")
    return null
  }

  console.info("session successfully refreshed!")
  return refreshResponse.refreshToken
}

interface SessionRefreshChallenge {
  challenge: string
  algorithm: string
}

interface SessionRefreshResponse {
  refreshToken: string
}
