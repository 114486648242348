import React, { useEffect, useState } from "react"
import Session from "../../common/types/api.session.type"
import { get } from "../../common/utils/apiCall"
import { registerSessionPoller } from "../../common/utils/sessionPoller"
import usePrevious from "../../common/utils/usePrevious"
import ExpiryPopup from "../../components/ExpiryPopup"
import NewFeatures from "../../components/NewFeatures/NewFeatures"
import PopupStack from "../../components/PopupStack"
import {
  clearBanner, //
  selectCurrentSessionOperatorInfo,
  selectPlatform,
  setPlatform,
  setSession,
  useAppDispatch,
  useAppSelector
} from "../../store"
import ErrorPage from "../ErrorPage"
import Header from "../Header"
import LoginForm from "../LoginForm"
import Main from "../Main"
import Nav from "../Nav"
import getPlatform from "./getPlatform"
import styles from "./viewPort.module.scss"

const Layout = (props: { children?: React.ReactNode }) => {
  // redux
  const operatorInfo = useAppSelector(selectCurrentSessionOperatorInfo)
  const prevOperatorInfo = usePrevious(operatorInfo)
  const platform = useAppSelector(selectPlatform)
  const dispatch = useAppDispatch()
  const [isConnectionRefused, setIsConnectionRefused] = useState(false)
  // translations
  const errorMessage =
    "Please check that your Command Centre server is running and connected to Gallagher cloud services," +
    " then try again. If this error persists, please contact your Command Centre system administrator"
  const errorTitle = "Cloud Connection Error"

  useEffect(() => {
    // if we are loading for the first time then clear any banner info that may be hanging around
    dispatch(clearBanner())
    dispatch(setPlatform(getPlatform()))

    if (!operatorInfo || !window.sessionPoll) {
      checkSessionState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkSessionState = async () => {
    let connFailure = false
    try {
      const sessionInfo = await get<Session>(window.GatewayEntryPointUrl)
      dispatch(setSession(sessionInfo))
      if (sessionInfo.refresh) {
        // start the session poller to refresh our authentication token while we are logged in
        const refreshIntervalMs = sessionInfo.refresh.interval * 1000
        registerSessionPoller(refreshIntervalMs)
      }
    } catch (err: any) {
      // Failed session get - require logon
      // Future: delay the display of the logon form until we confirm we dont have a session here
      // Introduce a placeholder "loading" screen to display in the meantime
      connFailure = err.httpStatusCode === 503 || err.httpStatusCode === 504
    }
    if (connFailure !== isConnectionRefused) setIsConnectionRefused(connFailure)
  }

  const cssClasses = [styles.viewport, styles[platform]]

  if (operatorInfo) cssClasses.push(styles.loggedIn)

  if (!prevOperatorInfo && operatorInfo) {
    // Since Main and LoginForm are rendered on the same route
    // we need to reset scrollY after logging in
    window.scrollTo({ top: 0 })
  }

  return (
    <section className={cssClasses.join(" ").trim()}>
      {operatorInfo ? (
        <>
          <Header />
          <Main>{props.children}</Main>
          <PopupStack components={[NewFeatures, ExpiryPopup]} />
          <Nav />
          <div id="breadcrumb-portal" className={styles.breadcrumbWrapper} />
        </>
      ) : isConnectionRefused ? (
        <ErrorPage message={errorMessage} title={errorTitle} icon={"network-error"} onRetry={checkSessionState} />
      ) : (
        <LoginForm checkSession={checkSessionState} />
      )}
    </section>
  )
}

export default Layout
