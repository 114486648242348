import { Button, SlideOutPanel } from "@ggl/components"
import { useEffect, useState } from "react"
import type { PopupStackComponentProps } from "../../components/PopupStack/PopupStack"
import { BTN_LABEL, Content, NOTES_URL, RELEASE_ID, TITLE } from "./Content"
import styles from "./newFeatures.module.css"
/**
 * This is a pop up which contains the latest features that have been released for this product.
 * Only the interaction logic is in this file, please check the Content.tsx to view or edit the pop up content and element labels.
 */
export type NewFeaturesProps = PopupStackComponentProps

const NewFeatures = ({ onClose }: NewFeaturesProps) => {
  // To be able to force a re-render we need track something, visibility is a good candidate
  const [dismissed, setDismissed] = useState(localStorage.getItem("newFeaturesDismissed") === RELEASE_ID)
  const visible = !dismissed

  //Calling onClose when there is no notification to show
  //Using useEffect to avoid calling onClose in the render phase
  //https://reactjs.org/link/setstate-in-render
  useEffect(() => {
    if (!visible) {
      onClose()
    }
  }, [visible, onClose])

  const closeHandler = () => {
    localStorage.setItem("newFeaturesDismissed", RELEASE_ID) // set the release ID so users only dismiss the pop up per release
    setDismissed(true) // this just triggers teh re-render
  }

  const actions = () => {
    return (
      <Button
        id="new-features-more"
        color="primary"
        onClick={() => {
          window.open(NOTES_URL)
        }}
      >
        {BTN_LABEL}
      </Button>
    )
  }

  return visible ? (
    <SlideOutPanel title={TITLE} actions={actions()} popUp zIndex={700} onUnmountReady={closeHandler} className={styles.slideOutPanel} id="new-features">
      <Content />
    </SlideOutPanel>
  ) : null
}

export default NewFeatures
