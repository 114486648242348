import { useCallback, useEffect, useRef, useState } from "react"

/**
 * A custom React hook that returns a debounced version of a value.
 * This hook is useful when you want to limit how often your component responds to changes in a value.
 *
 * @param value The value to debounce. This can be of any type.
 * @param delay The number of milliseconds to wait before updating the debounced value.
 * @returns A debounced version of the input value. This value will only update after the specified delay
 *          has passed without any new changes to the input value.
 *
 * @example
 * const [searchTerm, setSearchTerm] = useState("");
 * const debouncedSearchTerm = useDebouncedValue(searchTerm, 300);
 *
 * useEffect(() => {
 *   // This effect will only run 300ms after the last change to searchTerm
 *   performSearch(debouncedSearchTerm);
 * }, [debouncedSearchTerm]);
 *
 * @note The debounced value will always eventually reflect the latest input value, but delayed by the specified amount.
 *       This can be useful for performance optimization in scenarios like search inputs or other frequent updates.
 */
const useDebouncedValue = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

/**
 * A custom React hook that returns a debounced version of the provided callback function.
 * This is useful for optimizing performance by limiting the rate at which a function can fire.
 *
 * @param callback The function to debounce. This can be any function, including event handlers or state setters.
 * @param delay The number of milliseconds to wait before invoking the callback after the last call.
 * @returns A memoized, debounced version of the callback that will only invoke after the specified delay
 *          has passed since the last call. The returned function has the same signature as the input callback.
 *
 * @example
 * const debouncedSearch = useDebouncedCallback(searchFunction, 300);
 *
 * // In an event handler:
 * const handleInputChange = (e) => {
 *   debouncedSearch(e.target.value);
 * };
 *
 * @note This hook will return a new function reference when the delay changes, but not when the callback changes.
 *       The latest callback will always be used regardless of when the debounced function was created.
 */
const useDebouncedCallback = <T extends (...args: any[]) => any>(callback: T, delay: number) => {
  const callbackRef = useRef(callback)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  return useCallback(
    (...args: Parameters<T>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        callbackRef.current(...args)
      }, delay)
    },
    [delay]
  )
}

export { useDebouncedCallback, useDebouncedValue }
