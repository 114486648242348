import type { PDFAccessType, PDFDefinition, PdfNamingScheme } from "../../../common/types/api.personalDataField.type"
import type { AccessGroupConfig } from "../../../common/types/api.accessGroup.type"
import type { Card } from "../../../common/types/api.card.types"
import type { Link } from "../../../common/types/api.link.type"
import type { PatchCardholder } from "../../../common/types/api.cardholder.type"
import Cardholder, { CardholderAccessGroupUpdate, JsonOperation, NewCardholderAccessGroup, RemoveCard } from "../../../common/types/api.cardholder.type"
import PDFType from "../../../common/types/PDFEnum.type"
import invariant from "../../../common/utils/invariant"
import { patch } from "../../../common/utils/apiCall"
import deepEquals from "../../../common/utils/deepEquals"

/**
 * This gets all the PDF Notifications changes between the original cardholder and the edited version
 * @param oldPDDs
 * @param newPDDs
 * @returns Array of PDFs with notification changes
 */
export const getPDFNotificationChanges = (oldPDDs: Record<PdfNamingScheme, PDFDefinition>[], newPDDs: Record<PdfNamingScheme, PDFDefinition>[]) => {
  if (!newPDDs || newPDDs.length === 0) return []
  return newPDDs
    .filter((p) => {
      const newPdfDefinition = Object.values(p)[0] as PDFDefinition
      const name = newPdfDefinition.definition.name
      if (name === undefined || (newPdfDefinition.definition.type !== PDFType.Email && newPdfDefinition.definition.type !== PDFType.Mobile)) return false
      const oldPdd = oldPDDs?.find((p) => p[`@${name}`])
      if (!oldPdd) return true
      const oldPdfDefinition = Object.values(oldPdd)[0] as PDFDefinition
      if (newPdfDefinition.notifications !== oldPdfDefinition.notifications) return true
    })
    .map((p) => {
      const newPdfDefinition = Object.values(p)[0] as PDFDefinition
      const name = newPdfDefinition.definition.name
      const notifications = newPdfDefinition.notifications
      const result: any = {}
      result[`@${name}`] = { notifications }
      return result
    })
}

/**
 * This sanitises any dates to make sure they are compliant with what the format the API is expecting
 * @param value - this is a date string needing verified
 * @returns (Iso date | '' | string) if value type === string || Date. Else returns any type.
 */
export function sanitizeDates(value: unknown) {
  if (typeof value === "string" || value instanceof Date) {
    var date = new Date(value)
    return !isNaN(date.getTime()) ? date.toISOString() : value
  }
  return value
}

/**
 * This finds any objects that are in the new array but not in the old array
 * @param oldVal
 * @param newVal
 * @returns list of items to remove
 */
export function findItemsToRemove<T>(
  oldVal: Array<(T & Link) | { href?: string }>,
  newVal: Array<
    | (T & Link)
    | {
        href?: string
      }
  >
) {
  return oldVal.filter((x) => !newVal.some((y) => y.href === x.href)).map((remove) => ({ href: remove.href! }))
}

/**
 * This identifies any items which have been updated by comparing the original item to the draft one
 * @param origItemsList
 * @param newItemsList
 * @param updatableFields
 * @returns List of items with an update
 */
export function findItemsWithUpdates<T>(origItemsList: T[], newItemsList: T[], updatableFields: string[] = []) {
  const updated: Array<T> = []
  for (const old of origItemsList as any) {
    for (const new_ of newItemsList as any) {
      if (old.href !== new_.href) continue
      const updatedKeys = Object.keys(new_).filter((key) => (key !== "href" && !old.hasOwnProperty(key)) || (old as any)[key] !== (new_ as any)[key])
      if (updatedKeys.length > 0) {
        const updatedEntries = Object.entries(new_)
          .filter(([a]) => updatableFields.includes(a) && updatedKeys.includes(a))
          .map(([key, val]) => {
            if (key === "from" || key === "until") {
              return [key, sanitizeDates(val)]
            } else {
              return [key, val]
            }
          })
        updated.push({ href: new_.href, ...Object.fromEntries(updatedEntries) })
      }
    }
  }
  return updated
}

/**
 * Use this to form the JSON for patching one of the cardholder's array properties e.g. accessGroups, cards, etc.
 * @param orgConfigList
 * @param newConfigList
 * @returns
 */
export function getAccessGroupChanges(orgConfigList: AccessGroupConfig[] = [], newConfigList: AccessGroupConfig[] = []) {
  let changes: JsonOperation<NewCardholderAccessGroup, CardholderAccessGroupUpdate, Link> = {}

  const add = newConfigList
    .filter((x) => !orgConfigList.some((y) => y.href === x.href))
    .map((ag) => ({
      accessGroup: { href: ag.accessGroup.href },
      from: ag.from ? new Date(ag.from).toISOString() : undefined,
      until: ag.until ? new Date(ag.until).toISOString() : undefined
    }))
  if (add.length > 0) {
    changes.add = add
  }

  const update = findItemsWithUpdates<AccessGroupConfig>(orgConfigList, newConfigList, ["from", "until"])

  if (update.length > 0) {
    changes.update = update
  }

  const remove = findItemsToRemove<AccessGroupConfig>(orgConfigList, newConfigList)
  if (remove.length > 0) {
    changes.remove = remove
  }

  return changes
}

/**
 * This returns the difference between one an original card list and a new one
 * @param orgCardList
 * @param newCardsList
 * @returns A list of credential changes between teh two lists
 */
export function getCardChanges(orgCardList: Card[] = [], newCardsList: Card[] = []) {
  let changes: JsonOperation<Card, Card, RemoveCard> = {}

  // get any new cards
  let add = newCardsList.filter((x) => !orgCardList.some((y) => y.href === x.href))

  // BUG 113797: You don't assign card numbers for digital Id but this is
  // a hack so that the REST API doesn't complain when you are creating
  // two digital id credentials
  add = add.map((cred) => {
    if (cred.credentialClass === "digitalId")
      return {
        ...cred,
        number: Math.floor(Math.random() * 16777214 + 1).toString()
      }

    return cred
  })

  if (add.length > 0) changes.add = add

  // update any existing cards
  const edited = findItemsWithUpdates<Card>(orgCardList, newCardsList, ["from", "until", "status", "isDeleted", "pin"]).reduce<{
    removals: Card[]
    updates: Card[]
  }>(
    (cxt, i) => {
      i.isDeleted ? cxt.removals.push(i) : cxt.updates.push(i)
      return cxt
    },
    { removals: [], updates: [] }
  )

  if (edited.updates.length > 0) changes.update = edited.updates

  if (edited.removals.length > 0) {
    changes.remove = edited.removals.map((c) => {
      return { href: c.href!, status: c.status }
    })
  }
  return changes
}

/**
 * This will patch a cardholder with a credential supplied
 * @param card - credential
 * @param href - cardholder URL
 */
export async function createNewCredential(card: Card, href: string) {
  try {
    invariant(card)
    invariant(href)
    const payload = {
      cards: {
        add: [card]
      }
    }
    await patch(href, payload)
  } catch (err: any) {
    throw err
  }
}

/**
 * work out if the PDF is editable by this operator by comparing the the operator and default access setting.
 * It will will return true if it doesn't have enough information so that API can make the final call
 * @param pdfOperatorAccess - operator access property on a pdf
 * @param pdfDefaultAccess - default access properly on a PDF
 * @returns boolean
 */
function isEditable(pdfOperatorAccess?: PDFAccessType) {
  // if operatorAccess is not returned, let the server decide
  // if the operator should be able to edit this PDF
  return !!pdfOperatorAccess ? pdfOperatorAccess === "fullAccess" : true
}

/**
 * This builds the patch payload to send back to the server when an update to a cardholder is required
 * @param original - original cardholder
 * @param draft - the edited cardholder
 * @returns PatchCardholder - JSON payload to patch the cardholder
 */
export function getUserEdits(original: Cardholder, draft: Cardholder) {
  //Get all the unique keys from both cardholder and clone objects,
  const keys = Array.from(new Set([...Object.keys(original), ...Object.keys(draft ?? {})])).filter((key) => key !== "editable" && key !== "options")
  const payload: PatchCardholder = {}
  for (const key of keys) {
    const oldVal = (original as any)[key]
    const newVal = (draft as any)[key]
    if (key === "personalDataDefinitions") {
      const changes = getPDFNotificationChanges(oldVal, newVal).filter((item) => {
        // filter out any we can't edit
        const name = Object.keys(item)[0]
        if (name.startsWith("@")) {
          const operatorAccess = draft?.options?.[name as PdfNamingScheme]?.operatorAccess
          return isEditable(operatorAccess)
        }
        return true
      })

      if (changes.length > 0) {
        payload[key] = changes
      }
    } else if (key === "accessGroups") {
      const changes = getAccessGroupChanges(oldVal, newVal)
      if (Object.keys(changes).length > 0) {
        payload[key] = changes
      }
    } else if (key === "cards") {
      const changes = getCardChanges(oldVal, newVal)
      if (Object.keys(changes).length > 0) {
        payload[key] = changes
      }
    } else if (key.startsWith("@") && oldVal !== newVal) {
      const operatorAccess = draft?.options?.[key as PdfNamingScheme]?.operatorAccess
      if (isEditable(operatorAccess)) {
        payload[key as PdfNamingScheme] = newVal
      }
    } else if (typeof oldVal === "object" || typeof newVal === "object") {
      if (!deepEquals(oldVal, newVal)) payload[key as PdfNamingScheme] = newVal
    } else if (oldVal !== newVal) {
      payload[key as PdfNamingScheme] = newVal
    }
  }
  return payload
}
