import React, { useState, useEffect, useCallback } from "react"
import { Modal, Button, PasswordField, MessageBar, Label, Icon } from "@ggl/components"
import styles from "./changepassword.module.scss"
import { Link } from "../../common/types/api.link.type"
import { PasswordRestrictions, getPasswordRestrictions } from "./apicalls"
import { useAppDispatch, setBanner } from "../../store"
import { captureTelemetry } from "../../common/utils/postHog"

interface ChangePasswordProps {
  // The ChangePasswordDialog will call this callback when it wants to close the dialog for any reason
  closeHandler: () => void

  // The ChangePasswordDialog will call this callback to change the password.
  // it should return true to show the 'your password was changed' confirmation, or false to do nothing
  // If it fails, an ApiError with associated JSON metadata will be expected
  updatePasswordHandler: (password: string, newPassword: string) => Promise<boolean>

  // if we are arriving as part of logon-force-password-change, then these restrictions will be
  // a pre-populated PasswordRestrictions object. If not, they will be a Link which we will need to
  // use to fetch the restrictions dynamically
  passwordRestrictions: Link | PasswordRestrictions | undefined
}

interface PasswordRestrictionCriteria {
  key: string // unique identifier for this restriction
  isCompliant: boolean // whether the new password meets this criteria
  title: string // text explaining this criteria e.g. "Must be at least N characters"
}

const maxPatternSize = 3

const hasRepeatingCharacters = (str: string) => {
  if (str.length < maxPatternSize) {
    return false
  }

  // algorithm copied from the C++ one in PasswordPolicy.cpp
  const lower = str.toLowerCase()
  let count = 1
  let prevCh = lower[0]
  let skipFirst = true
  for (let ch of lower) {
    if (skipFirst) {
      // emulate lower.skip(1) from C#
      skipFirst = false
      continue
    }

    if (ch == prevCh) {
      count++
    } else {
      count = 1
    }

    if (count == maxPatternSize) {
      return true
    }
  }
  return false
}

const hasConsecutiveCharacters = (str: string) => {
  if (str.length < maxPatternSize) {
    return false
  }
  const lower = str.toLowerCase()

  // algorithm NOT copied from the C++ one in PasswordPolicy.cpp because it uses horrible pointer maths and runs over the end of the buffer :-(
  // instead we implement a 3-character sliding window, and each time we step the window we simply check if it's sequential.
  // NOTE this is probably mildly buggy. Remove this comment if you've fixed all the bugs
  const buf = [0, 0, 0] // js has immutable strings and no 'char' type, so this is the best we've got
  let allocated = 0

  const shiftAndVerify = (ch: number): boolean => {
    // returns true if it detects a sequential run of characters, false if not
    // this only handles english. yech
    if (!((ch >= 97 && ch <= 122) || (ch >= 65 && ch <= 90) || (ch >= 48 && ch <= 57))) {
      allocated = 0 // wipe the buffer, we need a run of AlphaNumeric characters to care about this.
      return false
    }

    if (allocated < buf.length) {
      buf[allocated++] = ch
    } else {
      // Debug.Assert(allocated == 3)
      buf[0] = buf[1]
      buf[1] = buf[2]
      buf[2] = ch

      //1, 2, 3
      if (
        (buf[0] + 2 == buf[1] + 1 && buf[1] + 1 == buf[2]) ||
        //3, 2, 1
        (buf[0] - 2 == buf[1] - 1 && buf[1] - 1 == buf[2])
      ) {
        return true
      }
    }
    return false
  }

  for (let i = 0; i < lower.length; i++) {
    if (shiftAndVerify(lower.charCodeAt(i))) {
      return true
    }
  }

  return false
}

const countComplexityGroups = (str: string) => {
  let hasLower = 0,
    hasUpper = 0,
    hasDigit = 0,
    hasSpecial = 0
  // english only because that's what the server matches
  for (let ch of str) {
    if (ch.match(/[a-z]/)) {
      hasLower = 1
    } else if (ch.match(/[A-Z]/)) {
      hasUpper = 1
    } else if (ch.match(/[0-9]/)) {
      hasDigit = 1
    } else if ("( !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~)".indexOf(ch) >= 0) {
      hasSpecial = 1
    }
  }
  return hasLower + hasUpper + hasDigit + hasSpecial
}

enum UiState {
  Loading,
  Interactive,
  ChangingPassword,
  PasswordChangeSucceeded
}

const ChangePasswordDialog = (props: ChangePasswordProps) => {
  const [capsLockOn, setCapsLockOn] = useState(false)
  const isCapsLockOn = (e: React.KeyboardEvent) => {
    if (e.getModifierState("CapsLock")) {
      return setCapsLockOn(true)
    } else {
      setCapsLockOn(false)
    }
  }
  // bindings for UI
  const [uiState, setUiState] = useState<UiState>(UiState.Loading)
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")

  // lazy-loaded list of password restrictions applied by the server. May be provided or we may have to go and load them
  const [restrictions, setRestrictions] = useState<PasswordRestrictions>()

  // when we try to save the password to the server, it may come back with extra errors over and above the ones we evaluate client-side
  const [serverRestrictionErrors, setServerRestrictionErrors] = useState<PasswordRestrictionCriteria[]>()

  // we evaluate the restrictions against the user-input and build the list of criteria (good/bad)
  const [passwordRestrictionCriteria, setPasswordRestrictionCriteria] = useState<PasswordRestrictionCriteria[]>()

  // if the new password complies with all restrictions, we're good to submit to the server
  const [isPasswordCompliant, setIsPasswordCompliant] = useState(false)

  const dispatch = useAppDispatch()
  const networkErrorMessage = "Network error"

  useEffect(() => {
    if (!restrictions) {
      setPasswordRestrictionCriteria([])
      return // not yet loaded
    }

    /* re-calculate all the things and produce passwordRestrictionCriteria */
    let criteria: PasswordRestrictionCriteria[] = []
    criteria.push({ key: "confirmMismatch", isCompliant: newPassword == confirmNewPassword, title: "New password must match confirmation" })

    if (restrictions.minimumPasswordLength) {
      const isCompliant = newPassword.length >= restrictions.minimumPasswordLength
      criteria.push({ key: "minimumPasswordLength", isCompliant: isCompliant, title: `Must be at least ${restrictions.minimumPasswordLength} characters` })
    }
    if (restrictions.restrictPatternsInPassword) {
      criteria.push({ key: "restrictRepeatingCharacters", isCompliant: !hasRepeatingCharacters(newPassword), title: `Must not contain repeating characters` })
      criteria.push({ key: "restrictConsecutiveCharacters", isCompliant: !hasConsecutiveCharacters(newPassword), title: `Must not contain consecutive characters` })
    }

    if (restrictions.complexPasswords && restrictions.complexPasswordGroups) {
      const cxGroups = countComplexityGroups(newPassword)
      const msg =
        restrictions.complexPasswordGroups == 4
          ? "Must contain one of each of upper case, lower case, numbers and special characters."
          : "Must contain at least 3 out of 4 of upper case, lower case, numbers and special characters"

      criteria.push({ key: "complexPasswordGroups", isCompliant: cxGroups >= restrictions.complexPasswordGroups, title: msg })
    }

    // if we pass all the client-side tests then light up the "change" button
    setIsPasswordCompliant(criteria.every((c) => c.isCompliant))

    // patch in any extra errors the server told us about e.g. password history
    if (serverRestrictionErrors) {
      for (const s of serverRestrictionErrors) {
        criteria.push(s)
      }
    }

    setPasswordRestrictionCriteria(criteria)
  }, [newPassword, confirmNewPassword, restrictions, serverRestrictionErrors])

  useEffect(() => {
    // caller must supply passwordRestrictions in the props, it's not allowed to be null. Safety-net bail out though rather than crash
    if (!props.passwordRestrictions) {
      return
    }

    if ("href" in props.passwordRestrictions) {
      const getPasswordRestrictionsHref = props.passwordRestrictions.href
      // we don't have any restrictions, but we are capable of fetching them, off we go
      ;(async () => {
        try {
          const fetchedRestrictions = await getPasswordRestrictions(getPasswordRestrictionsHref)
          setUiState(UiState.Interactive)
          setRestrictions(fetchedRestrictions)
        } catch (err: any) {
          dispatch(setBanner({ color: "destructive", message: err.message ?? networkErrorMessage }))
          props.closeHandler()
        }
      })()
    } else {
      // it must be a PasswordRestrictions object already
      setUiState(UiState.Interactive)
      setRestrictions(props.passwordRestrictions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const doChangePassword = useCallback(async () => {
    // temporarily disable the "change" button to stop double-click
    setUiState(UiState.ChangingPassword)
    setServerRestrictionErrors([])
    try {
      const shouldShowConfirmationDialog = await props.updatePasswordHandler(oldPassword, newPassword)
      if (shouldShowConfirmationDialog) {
        setUiState(UiState.PasswordChangeSucceeded)
        captureTelemetry("Clicked (Done) Change Password")
      }
      // else do nothing. The caller is going to close us immediately anyway
    } catch (err: any) {
      if ("message" in err && "code" in err) {
        setServerRestrictionErrors([{ key: err.code.toString(), title: err.message, isCompliant: false }])
      } else {
        console.warn("err: " + err) // this means the server sent us something weird, which shouldn't happen
        setServerRestrictionErrors([{ key: "unknownError", title: `Unknown: ${err}`, isCompliant: false }])
      }
      setUiState(UiState.Interactive)
    }
  }, [props, oldPassword, newPassword])

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Escape") {
        event.preventDefault()
        props.closeHandler()
      }
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (uiState == UiState.Interactive && isPasswordCompliant) {
          event.preventDefault()
          doChangePassword()
        } else if (uiState == UiState.PasswordChangeSucceeded) {
          props.closeHandler()
        }
      }
    }
    document.addEventListener("keydown", listener)
    return () => document.removeEventListener("keydown", listener)
  }, [props, doChangePassword, uiState, isPasswordCompliant])

  const renderActionButtons = () =>
    uiState == UiState.PasswordChangeSucceeded ? (
      <Button id="ok_button" color="primary" onClick={props.closeHandler} className="ok-btn">
        Ok
      </Button>
    ) : (
      [
        <Button
          id="cancel_button"
          onClick={() => {
            props.closeHandler()
            captureTelemetry("Clicked (Cancel) Change Password")
          }}
          color="secondary"
          className="cancel-btn"
          disabled={uiState != UiState.Interactive}
          key="change-password-cancel"
        >
          Cancel
        </Button>,
        <Button
          id="change_password_button"
          color="primary"
          onClick={doChangePassword}
          className="ok-btn"
          loading={uiState == UiState.Loading}
          disabled={uiState != UiState.Interactive || !isPasswordCompliant}
          key="change-password-change"
        >
          Change password
        </Button>
      ]
    )

  const modalTitleText = props.passwordRestrictions && "href" in props.passwordRestrictions ? "Change password" : "Password change required to log on"

  return (
    <Modal title={modalTitleText} required actions={renderActionButtons()} opaque>
      {uiState == UiState.PasswordChangeSucceeded ? (
        <div style={{ textAlign: "center", alignItems: "center" }}>
          <Icon type={"check"} className={`${styles.bigIcon} ${styles.good}`} />
          <Label style={{ justifyContent: "center" }} text="Your password has been changed." />
        </div>
      ) : (
        <form>
          {capsLockOn && <MessageBar text={"Caps lock is enabled"} color="attention" />}
          <PasswordField
            autoComplete="off"
            placeholder="Password"
            id="old_password_textbox"
            autoFocus
            onChange={(e: any) => setOldPassword(e.target.value)}
            label="Current Password:"
            value={oldPassword}
            onKeyPress={isCapsLockOn}
          />
          <div className={styles.newPasswordInput}>
            <PasswordField
              autoComplete="off"
              placeholder="Password"
              id="new_password_textbox"
              onChange={(e: any) => setNewPassword(e.target.value)}
              label="New Password:"
              value={newPassword}
              onKeyPress={isCapsLockOn}
            />
          </div>

          <PasswordField
            autoComplete="off"
            placeholder="Password"
            id="confirm_password_textbox"
            onChange={(e: any) => setConfirmNewPassword(e.target.value)}
            label="Confirm New Password:"
            value={confirmNewPassword}
            onKeyPress={isCapsLockOn}
          />

          <Label text="Restrictions:" />
          <div id="restrictions_container">
            {passwordRestrictionCriteria?.map((criteria) => (
              <div key={criteria.key} className={styles.restrictionsRow}>
                {criteria.isCompliant ? <Icon type={"check"} className={`${styles.inlineIcon} ${styles.good}`} /> : <Icon type={"error"} className={`${styles.inlineIcon} ${styles.bad}`} />}{" "}
                {criteria.title}
              </div>
            ))}
          </div>
        </form>
      )}
    </Modal>
  )
}

export default ChangePasswordDialog
