import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import Cardholder, { CardholderWithThumbnail } from "../../common/types/api.cardholder.type"
import { RootState } from "../../store/store"

interface InitialState {
  timestamp?: Date
  results?: Array<Cardholder | CardholderWithThumbnail>
}

export const initialState: InitialState = {}

export const cardholderSearchSliceOld = createSlice({
  name: "cardholderSearchOld",
  initialState,
  reducers: {
    setResults: (state, action: PayloadAction<Cardholder[]>) => {
      state.results = action.payload
      return state
    }
  }
})

export const selectResults = (state: RootState) => {
  return state.cardholderSearchOld.results
}

export const { setResults } = cardholderSearchSliceOld.actions
export default cardholderSearchSliceOld.reducer
