import { TimeoutError } from "./errors"

export interface IPoll {
  readonly halted: boolean

  start(): void
  stop(): void
}

type PollConfig<T> = {
  intervalMS: number
  callback: (data?: T, signal?: AbortSignal) => T | Promise<T>
  initialValue?: T

  onError?: (error: any) => void

  timeoutMS?: number
  onTimeout?: () => void
}

/**
 * Use this when you require
 *   - running a lambda every x period of time
 *   - http long polling
 *
 * You can pass in a sync or async callback. The next poll will run after intervalMS + how long the callback took.
 *
 * Use the AbortSignal passed to the callback to terminate its execution when it timed out or the poll has been stopped.
 */
export class Poll<T> implements IPoll {
  readonly #config: PollConfig<T>

  // polling
  #halted: boolean = true
  #lastValue?: T
  #nextPollTimeoutId?: NodeJS.Timeout

  // timeouts
  #timeoutId?: NodeJS.Timeout
  #abortController: AbortController = new AbortController()

  constructor(config: PollConfig<T>) {
    this.#config = config
    this.#lastValue = this.#config.initialValue
  }

  get halted() {
    return this.#halted
  }

  start = () => {
    if (!this.#halted) return
    this.#halted = false
    this.#poll()
  }

  #poll = async () => {
    if (this.#timeoutId) clearTimeout(this.#timeoutId)
    if (this.#halted) return

    try {
      this.#abortController = new AbortController()
      const promises = [] as Promise<T>[]

      // Timeout
      if (this.#config.onTimeout && this.#config.timeoutMS) {
        var timeoutPromise = new Promise<T>((_, reject) => {
          this.#timeoutId = setTimeout(() => {
            this.#abortController?.abort()
            reject(new TimeoutError())
          }, this.#config.timeoutMS)
        })
        promises.push(timeoutPromise)
      }

      // The callback
      promises.push(Promise.resolve(this.#config.callback(this.#lastValue, this.#abortController.signal)))

      this.#lastValue = await Promise.race(promises)
    } catch (error) {
      if (error instanceof TimeoutError) {
        this.#abortController.abort()
        this.#config.onTimeout?.()
      } else {
        this.#config.onError?.(error)
      }
    } finally {
      this.#nextPollTimeoutId = setTimeout(this.#poll, this.#config.intervalMS)
    }
  }

  stop = () => {
    this.#halted = true
    if (this.#timeoutId) clearTimeout(this.#timeoutId)
    if (this.#nextPollTimeoutId) clearTimeout(this.#nextPollTimeoutId)
    this.#abortController.abort()
  }
}
