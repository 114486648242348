import { Backdrop } from "@ggl/components"
import { useRouter } from "next/router"
import React from "react"
import { selectDrawerClosed, selectFeatures, selectResponsiveLayout, setDrawerClosed, useAppDispatch, useAppSelector } from "../../store"
import styles from "./nav.module.scss"
import NavItem, { NavItemProps } from "./NavItem"
import { captureTelemetry } from "../../common/utils/postHog"

const HOME = "Dashboard"

const Nav: React.FC = () => {
  const router = useRouter()
  // redux
  const features = useAppSelector(selectFeatures) ?? {}
  const dispatch = useAppDispatch()
  const drawerClosed = useAppSelector(selectDrawerClosed)
  const layout = useAppSelector(selectResponsiveLayout)
  // ui state
  const menuItems: Map<string, Omit<NavItemProps, "href"> & { alias?: string[] }> = new Map([
    [
      "/",
      {
        title: HOME,
        icon: "home",
        onClick: () => {
          captureTelemetry("Clicked (Nav) Cardholders")
        }
      }
    ]
  ])

  // this sets the open/close state in redux
  const toggleHandler = () => {
    dispatch(setDrawerClosed(!drawerClosed))
  }
  // this updates the route handler so a new template/page is loaded
  const routeChangeHandler = (targetHref: string) => {
    router.push(targetHref)
    if (!drawerClosed) {
      if (window.innerWidth <= 860) {
        setTimeout(() => {
          toggleHandler()
        }, 150)
      } else {
        toggleHandler()
      }
    }
  }

  // work out what CSS classes we need to attach at this point in time
  const cssClasses = [styles.nav]
  const backdropCss = [styles.backdrop]
  drawerClosed ? cssClasses.push(styles.closed) : backdropCss.push(styles.open)

  return (
    <>
      <Backdrop onClick={toggleHandler} className={backdropCss.join(" ").trim()} opaque allowBodyScroll={drawerClosed || layout === "desktop"} />
      <nav className={cssClasses.join(" ").trim()} role="navigation" aria-label="Main navigation list.">
        <ol className={styles.items}>
          <li className={styles.navHeader}>
            <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.8202 0L0 7.96889V24.0311L13.8202 32L27.5088 23.9979V8.00207C22.9463 5.33432 18.3839 2.66775 13.8202 0ZM13.7544 1.27521C15.1263 2.0894 16.4982 2.9036 17.8701 3.71779C17.5939 3.89674 17.3164 4.07452 17.0402 4.25347C15.9668 3.60638 14.8935 2.9593 13.8202 2.31221C12.714 2.9593 11.6078 3.60638 10.5004 4.25347C10.2464 4.05318 9.99133 3.85171 9.7374 3.65142C11.0764 2.85975 12.4154 2.06807 13.7532 1.27521H13.7544ZM6.15303 25.2352L5.19022 25.7709L1.07449 23.3117L5.32306 20.8526L6.15303 21.3551L2.86726 23.2963L6.15303 25.2376V25.2352ZM5.25723 11.1143C3.86298 10.2894 2.46874 9.46335 1.07449 8.63849C2.47932 7.8243 3.88532 7.01011 5.29015 6.19592C5.5335 6.35236 5.77684 6.5088 6.02019 6.66405C4.92454 7.31114 3.82889 7.95822 2.73442 8.60531L6.15303 10.6129C5.85443 10.78 5.55583 10.9472 5.25723 11.1154V11.1143ZM13.7544 30.7236L9.73858 28.281L10.5685 27.7122L13.7556 29.6866L17.0413 27.7122L17.8713 28.3142L13.7556 30.7236H13.7544ZM19.5301 19.2799L13.7544 22.6267L8.04573 19.3806V12.6206L13.8049 9.27373L19.5312 12.6206V19.2799H19.5301ZM22.2856 25.7721L21.4886 25.2364L24.7414 23.3117L21.4886 21.3207L22.2515 20.8526L26.4002 23.3117L22.2845 25.7709L22.2856 25.7721ZM22.2856 11.1154L21.4886 10.6473L24.7744 8.65627L21.4886 6.7316L22.2856 6.19592L26.4014 8.65509L22.2856 11.1143V11.1154ZM8.80869 13.2902L13.2571 15.9508V21.3882L8.80869 18.8449V13.2902ZM13.8038 10.3451L18.0359 12.7711L13.7544 15.1971L9.57164 12.7877L13.8038 10.3451ZM14.1517 15.9496L18.6002 13.289V18.9113L14.1517 21.3871V15.9496Z"
                fill="url(#paint0_linear_2128_2930)"
              />
              <defs>
                <linearGradient id="paint0_linear_2128_2930" x1="3.07434e-07" y1="16" x2="27.4286" y2="16" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#FF8400" />
                  <stop offset="1" stopColor="#FF7900" />
                </linearGradient>
              </defs>
            </svg>
          </li>
          {Array.from(menuItems).map(([path, { alias, ...item }]) => {
            return <NavItem key={item.title} active={[path, ...(alias ?? [])].includes(window.location.pathname)} href={path} {...item} onClick={routeChangeHandler} />
          })}
        </ol>
      </nav>
    </>
  )
}

export default Nav
