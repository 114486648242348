import { PDFItem } from "../types/api.personalDataField.type"

type SortablePdf = Pick<PDFItem, "name" | "sortPriority">

// This will sort PDFs based on priority and name.
export function pdfSort(a: SortablePdf, b: SortablePdf) {
  const aSort = a.sortPriority ?? 50
  const bSort = b.sortPriority ?? 50

  if (aSort !== bSort) return aSort - bSort

  const aName = a.name ?? ""
  const bName = b.name ?? ""

  return aName.localeCompare(bName, undefined, { numeric: true, sensitivity: "base" })
}
