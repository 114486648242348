import { get, post } from "../../common/utils/apiCall"

// this is the api call to log a user in
// this has been extracted for easy mocking
export async function changePassword(url: string, oldPassword: string, newPassword: string) {
  return await post<void>(url, {
    password: oldPassword,
    newPassword: newPassword
  })
}

export interface PasswordRestrictions {
  minimumPasswordLength?: number
  passwordsRemembered?: number
  complexPasswords?: boolean
  complexPasswordGroups?: number // either 3 or 4
  restrictPatternsInPassword?: boolean
}

export function getPasswordRestrictions(url: string): Promise<PasswordRestrictions> {
  return get<PasswordRestrictions>(url)
}
