import React, { useState } from "react"
import styles from "./errorpage.module.scss"
import { Button, ErrorMessage, ErrorMessageProps } from "@ggl/components"

interface ErrorPageProps extends ErrorMessageProps {
  onRetry?: () => Promise<any>
}

const ErrorPage = ({ message, title, icon, onRetry }: ErrorPageProps) => {
  const [isLoading, setIsLoading] = useState(false)

  function retry(onRetry?: () => Promise<any>) {
    setIsLoading(true)
    onRetry ? onRetry().then(() => setIsLoading(false)) : window.location.reload()
  }

  return (
    <div className={styles.root}>
      <section className={styles.container}>
        <ErrorMessage title={title} message={message} icon={icon} />
        <Button onClick={() => retry(onRetry)} text="Refresh" id="load_button" loading={isLoading} color="primary" />
      </section>
    </div>
  )
}

export default ErrorPage
