import type { Card } from "../common/types/api.card.types"
import type { AccessGroupConfig } from "common/types/api.accessGroup.type"
import type { CardType } from "../common/types/api.cardType.types"
import type { Link, LinkWithName } from "../common/types/api.link.type"
import { PdfNamingScheme, PDFOption } from "../common/types/api.personalDataField.type"
import PDFType from "../common/types/PDFEnum.type"
import Cardholder, { EditableCardholder } from "../common/types/api.cardholder.type"
import { createSlice, current, Draft, PayloadAction } from "@reduxjs/toolkit"
import { ActivityEvent } from "components/Cardholder/Activity/ActivityTab"
import deepEquals from "../common/utils/deepEquals"
import invariant from "../common/utils/invariant"
import isBase64Encoded from "../common/utils/isBase64Encoded"
import { getUserEdits } from "../components/Cardholder/common/helpers"
import * as thunks from "./thunks"

interface PersonPageInterface {
  thumbnail?: string
  profileImage?: string
  hasChanges?: boolean
  isValid?: boolean
  readonly original?: Cardholder | null
  editable?: EditableCardholder | null
  activeTab: number
  activityResults?: Array<ActivityEvent>
  activityUpdates?: string
  cardTypes?: Array<CardType>
  divisions?: LinkWithName[]
}

const MAX_NUMBER_OF_EVENTS = 200 //Maximum number of events to display when streaming events

export const personPageInitialState: PersonPageInterface = {
  activeTab: -1, // Defaults to the first tab
  isValid: true
}

const hasChanges = (state: Draft<PersonPageInterface>) => {
  return !deepEquals(state.original, state.editable)
}

export const personSlice = createSlice({
  name: "person",
  initialState: personPageInitialState,
  reducers: {
    updateCardholder: (state, action: PayloadAction<{ cardholder?: Cardholder }>) => {
      state.editable = action.payload.cardholder
      state.hasChanges = hasChanges(state)
    },
    updateCardholderNotification: (state, action: PayloadAction<{ name: PdfNamingScheme; notifications: boolean }>) => {
      let definition = state.editable?.personalDataDefinitions?.find((p) => p[action.payload?.name])
      if (!definition) {
        definition = {}
        state.editable?.personalDataDefinitions?.push(definition)
      }
      definition[action.payload.name].notifications = action.payload.notifications
      state.hasChanges = hasChanges(state)
    },
    updateProperty: (state, action: PayloadAction<{ name: PdfNamingScheme; value: any }>) => {
      if (state.editable) {
        const { name, value } = action.payload

        if (value === undefined) delete state.editable[name]
        else state.editable[name] = value

        state.hasChanges = hasChanges(state)
      }
    },
    updateIsValid(state, action: PayloadAction<boolean>) {
      state.isValid = action.payload
    },
    setAccessGroups: (state, action: PayloadAction<AccessGroupConfig[]>) => {
      if (state.editable) {
        if (!state.editable.editable) state.editable.editable = {}
        if (!state.editable.editable.accessGroups) state.editable.editable.accessGroups = {}
        if (!state.editable.editable.accessGroups.edit) state.editable.editable.accessGroups.edit = {}
        for (const ag of action.payload) {
          if (!state.editable.editable.accessGroups.edit.hasOwnProperty(ag.href)) {
            state.editable.editable.accessGroups.edit[ag.href] = true
          }
        }
        // upon removing all access groups the property itself needs to be removed to reflect state.original
        if (action.payload.length > 0) state.editable.accessGroups = action.payload
        else delete state.editable.accessGroups
        // if there's no difference between the original and the new access groups, then there are no changes
        if (deepEquals(state.editable?.accessGroups, state.original?.accessGroups)) {
          state.editable.editable.accessGroups.edit = state.original?.editable?.accessGroups?.edit
        }
      }

      state.hasChanges = hasChanges(state)
    },
    addAccessGroups: (state, action: PayloadAction<AccessGroupConfig[]>) => {
      // access groups not added if it already exists
      if (action.payload?.length === 0) return
      if (!state.editable) return
      if (!state.editable.accessGroups) state.editable.accessGroups = []
      if (!state.editable.editable) state.editable.editable = {}
      if (!state.editable.editable.accessGroups) state.editable.editable.accessGroups = {}
      if (!state.editable.editable.accessGroups.edit) state.editable.editable.accessGroups.edit = {}

      for (const ag of action.payload) {
        state.editable.accessGroups.push(ag) // you can add access groups multiple times

        if (!state.editable.editable.accessGroups.edit.hasOwnProperty(ag.href)) {
          state.editable.editable.accessGroups.edit[ag.href] = true
        }
      }

      state.hasChanges = hasChanges(state)
    },
    addPdfs: (state, action: PayloadAction<(PDFOption & Link & { value: string; notifications?: boolean })[]>) => {
      if (action.payload?.length === 0) return
      if (!state.editable) return
      if (!state.editable.personalDataDefinitions) state.editable.personalDataDefinitions = []
      if (!state.editable.options) state.editable.options = {}

      for (const newPdf of action.payload) {
        // set value
        if (newPdf.type !== PDFType.Image || isBase64Encoded(newPdf.value)) {
          state.editable[`@${newPdf.name}`] = newPdf.value
        }

        // add to options
        state.editable.options[`@${newPdf.name}`] = { ...newPdf }

        // set notifications
        if (newPdf.notifications === undefined) continue

        const pdf = state.editable.personalDataDefinitions?.find((pdf_) => pdf_.hasOwnProperty(`@${newPdf.name}`))
        if (pdf) {
          pdf[`@${newPdf.name}`].notifications = newPdf.notifications
        } else {
          state.editable.personalDataDefinitions.push({
            [`@${newPdf.name}`]: { href: "", value: newPdf.value, notifications: newPdf.notifications, definition: { href: newPdf.href, name: newPdf.name, type: newPdf.type } }
          })
        }
      }

      state.hasChanges = hasChanges(state)
    },
    addCard: (state, action: PayloadAction<Card>) => {
      if (!state.editable) return
      if (!state.editable.cards) state.editable.cards = []
      state.editable.cards.push(action.payload)
      state.hasChanges = true
    },
    removeCard: (state, action: PayloadAction<Card>) => {
      if (state.editable?.cards) {
        let key: keyof Card = "href"
        if (key !== undefined) {
          const i: number = state.editable.cards.findIndex((card) => JSON.stringify(card[key]) === JSON.stringify(action.payload[key]))
          if (i >= 0) {
            state.editable.cards.splice(i, 1)
          }
        }
      }
      state.hasChanges = hasChanges(state)
    },
    toggleAuth: (state) => {
      state.editable!.authorised = !state.editable!.authorised
      state.hasChanges = hasChanges(state)
    },
    updateCard: (state, action: PayloadAction<Card>) => {
      if (state.editable?.cards) {
        let key: keyof Card = "href"
        if (!action.payload.href) {
          // if we have a card number
          if (action.payload.number) {
            key = "number"
          } else if (action.payload.invitation) {
            key = "invitation"
          }
        }
        if (key !== undefined) {
          const i: number = state.editable.cards.findIndex((card) => card[key] === action.payload[key])
          if (i >= 0) state.editable.cards[i] = action.payload
        }
      }
      state.hasChanges = hasChanges(state)
    },
    resetCardholder: (state, action: PayloadAction<number | undefined>) => {
      if (action.payload) state.activeTab = action.payload
      state.editable = state.original
      state.hasChanges = false
      state.isValid = true
    },
    updateCurrentTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload
    },
    clearState: (state) => {
      return { ...personPageInitialState, activeTab: state.activeTab }
    },
    updateNotes(state, action: PayloadAction<string>) {
      state.editable!.notes = action.payload
      state.hasChanges = state.editable?.notes !== state.original?.notes
    },
    appendNote(state, action: PayloadAction<string>) {
      state.editable!.notes = state.editable!.notes ? `${state.editable!.notes}\n${action.payload}` : action.payload
      state.hasChanges = state.editable?.notes !== state.original?.notes
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.fetchCardholder.fulfilled, (state, action) => {
        state.original = action.payload
        state.editable = action.payload
        state.hasChanges = false
      })
      .addCase(thunks.fetchCardholder.rejected, (state, action) => {
        state.original = null
      })
      .addCase(thunks.fetchActivity.fulfilled, (state, action) => {
        state.activityResults = [...action.payload.events.reverse(), ...(state.activityResults ?? [])].slice(0, MAX_NUMBER_OF_EVENTS)
        state.activityUpdates = action.payload.updates.href
      })
      .addCase(thunks.fetchCardTypes.fulfilled, (state, action) => {
        state.cardTypes = action.payload
      })
      .addCase(thunks.fetchAndMergeFields.fulfilled, (state, action) => {
        // these all need to exist
        invariant(action.payload?.cardholder)
        invariant(state.original)
        invariant(state.editable)
        // extract all user changes
        const original = current(state.original)
        // create new source of the truth
        const newOriginal = { ...original, ...action.payload.cardholder } as Cardholder
        state.original = { ...newOriginal }
        const { cards: editedCards, accessGroups: editedAccessGroups } = current(state.editable)
        // retrieve the user changes, split off cards & AG as they will be a different format
        const { cards: patchCards, accessGroups: patchGroups, ...userEdits } = getUserEdits(original, current(state.editable))
        let updatedCards = newOriginal?.cards ? [...newOriginal?.cards] : []
        let updatedGroups = newOriginal?.accessGroups ? [...newOriginal?.accessGroups] : []
        // quick inline function to cherry pick edited items
        const cherryPick = <T extends Link>(list: any[], source: Array<T>, target: Array<T>) => {
          list.forEach((pc) => {
            const edited = source?.find((c) => c.href === pc.href)
            const index: number = target.findIndex((c) => {
              return c.href === pc.href
            })
            if (index > -1) target[index] = edited as T
          })
          return target
        }

        // copy over any items that have been edited but not saved
        if (patchCards?.update) {
          updatedCards = cherryPick<Card & Link>(patchCards.update, editedCards as Array<Card & Link>, updatedCards as Array<Card & Link>)
        }

        if (patchGroups?.update) {
          updatedGroups = cherryPick<AccessGroupConfig>(patchGroups.update, editedAccessGroups as Array<AccessGroupConfig>, updatedGroups)
        }

        if (patchCards?.remove) {
          updatedCards = cherryPick<Card & Link>(patchCards.remove, editedCards as Array<Card & Link>, updatedCards as Array<Card & Link>)
        }

        if (patchGroups?.remove) {
          updatedGroups = cherryPick<AccessGroupConfig>(patchGroups.remove, editedAccessGroups as Array<AccessGroupConfig>, updatedGroups as Array<AccessGroupConfig>)
        }
        state.editable = { ...newOriginal, ...userEdits, cards: updatedCards, accessGroups: updatedGroups } as Cardholder
        state.hasChanges = hasChanges(state)
      })
      .addCase(thunks.fetchDivisions.fulfilled, (state, action) => {
        state.divisions = action.payload
      })
      .addCase(thunks.fetchDivisions.rejected, (state, action) => {
        state.divisions = []
      })
  }
})

export const {
  updateCardholder,
  updateCardholderNotification,
  resetCardholder,
  clearState,
  updateCurrentTab,
  updateProperty,
  updateIsValid,
  setAccessGroups,
  addAccessGroups,
  addPdfs,
  addCard,
  removeCard,
  updateCard,
  toggleAuth,
  updateNotes,
  appendNote
} = personSlice.actions
export default personSlice.reducer
