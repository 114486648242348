import { Banner } from "@ggl/components"
import { useCallback, useMemo } from "react"
import useLocalStorage from "../../common/utils/useLocalStorage"
import Version from "../../common/utils/version"
import { selectCommandCentreVersion, useAppSelector } from "../../store"
import styles from "./main.module.scss"

const Labels = {
  EarlyAccess1: "From CCv9.10 onwards, CC Web is moving out of Early Access. See ",
  EarlyAccess2: "here",
  EarlyAccess3: " for more info."
} as const

const SUBSCRIPTIONS_FLYER_URL = "https://security.gallagher.com/-/media/Bynder/Security/Document/Flyer/Command-Centre-Web-Subscriptions-Flyer-original.pdf"
const LOCALSTORAGE_KEY = "earlyAccessBanner"

interface EarlyAccessBannerLocalStorageValue {
  /**
   * When this banner was last dismissed
   */
  dismissedTimestamp?: string
}

/**
 * Displays a banner when the version is less than 9.10. Showed once a day using localstorage to keep track.
 */
const EarlyAccessBanner = () => {
  const ccVersion = useAppSelector(selectCommandCentreVersion)
  const showEarlyAccess = Version.lessThan(ccVersion, "9.10.0")

  const { value, setLocalStorageValue } = useLocalStorage<EarlyAccessBannerLocalStorageValue>(LOCALSTORAGE_KEY, {})
  const showAgainDate = useMemo(() => {
    const lastDismissedDate = new Date(value.dismissedTimestamp ?? 0)
    const showAgainDate = new Date(lastDismissedDate)
    showAgainDate.setDate(showAgainDate.getDate() + 1)
    showAgainDate.setHours(0, 0, 0, 0)
    return showAgainDate
  }, [value.dismissedTimestamp])

  const handleOnDismiss = useCallback(() => {
    setLocalStorageValue({ dismissedTimestamp: new Date().toISOString() })
  }, [setLocalStorageValue])

  const showBanner = showEarlyAccess && showAgainDate <= new Date()

  return showBanner ? (
    <div className={styles.bannerContainer}>
      <Banner id="early-access-banner" className={styles.banner} onDismiss={handleOnDismiss}>
        <p>
          {Labels.EarlyAccess1}
          <a href={SUBSCRIPTIONS_FLYER_URL} target="_blank" rel="noreferrer">
            {Labels.EarlyAccess2}
          </a>
          {Labels.EarlyAccess3}
        </p>
      </Banner>
    </div>
  ) : null
}

export default EarlyAccessBanner
