import { get, post } from "../../common/utils/apiCall"
import Session from "../../common/types/api.session.type"
import { PDFItem } from "../../common/types/api.personalDataField.type"
import Cardholder from "../../common/types/api.cardholder.type"
import { CustomError, ErrorList } from "../../common/utils/errors"

// this is the api call to log a user in
// this has been extracted for easy mocking
export function login(url: string, userName: string, password: string, locale: string, timeZone: string, mfaCode?: string, newPassword?: string): Promise<Session> {
  const loginBody: any = { userName: userName, password: password, locale: locale, timeZone: timeZone }
  if (mfaCode) {
    loginBody["mfaCode"] = mfaCode
  }
  if (newPassword) {
    loginBody["newPassword"] = newPassword
  }
  return post<Session>(url, loginBody)
}

// this gets the global PDFs definition
// this has been extracted for easy mocking
export async function getAllPdfs(url: string) {
  const pdfList = await get<{ results: Array<PDFItem> }>(url)
  if (!pdfList) throw new CustomError(ErrorList.Unknown)
  return await Promise.all(
    pdfList.results.map(async (item) => {
      return (await get<PDFItem>(item.href)) as PDFItem
    })
  )
}

export async function getCardholder(url: string) {
  return get<Cardholder>(url)
}
